
import React, {Component, useState, useEffect} from 'react';
import { Switch } from 'react-router';
import {Route, Redirect, withRouter, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'underscore';

import DatabaseService from 'services/DatabaseService.js';
import {fetchMyProfile} from 'actions/user';
import {fetchBadgeNumbers} from 'actions/user';

import RoleUtils from 'utils/RoleUtils'

import PageNotFound                   from 'modules/main/PageNotFound.js';
import DefaultLayout                  from 'modules/main/DefaultLayout.js';
import SignUpContainer                from 'modules/authentication/SignUpContainer.js';
import LogInContainer                 from 'modules/authentication/LogInContainer.js';
import ForgotPasswordContainer        from 'modules/authentication/ForgotPasswordContainer.js';
import SurveyContainer                from 'modules/survey/SurveyContainer';
import DashboardContainer             from 'modules/dashboard/DashboardContainer.js';
import MarketingContainer             from 'modules/marketing/MarketingContainer.js';
import RewardsContainer               from 'modules/rewards/RewardsContainer.js';
import RewardsContainerV2               from 'modules/rewards/V2/RewardsContainerV2.js';
import Calendar                       from 'modules/misc/Calendar.js';
import CategoriesContainer            from 'modules/categories/CategoriesContainer.js';
import LeaderboardContainer           from 'modules/leaderboards/LeaderboardContainer.js';
import CategoryContainer              from 'modules/categories/CategoryContainer.js';
import PathwayContainer               from 'modules/categories/PathwayContainer.js';
import ObjectivesContainer            from 'modules/categories/ObjectivesContainer.js';
import EmployeesContainer             from 'modules/employees/EmployeesContainer.js';
import CompaniesContainer             from 'modules/companies/CompaniesContainer.js';
import AccountContainer               from 'modules/account/AccountContainer.js';
import AnnouncementsContainer         from 'modules/announcements/AnnouncementsContainer.js';
import HraContainer                   from 'modules/hra/HraContainer.js';
import FwaContainer                   from 'modules/fwa/FwaContainer.js';
import ReportsContainer               from 'modules/reports/ReportsContainer.js';
import RiskProfilesContainer          from 'modules/riskprofiles/RiskProfilesContainer.js';
import SegmentsContainer              from 'modules/segments/SegmentsContainer.js';
import TeamsContainer                 from 'modules/teams/TeamsContainer.js';
import TeamDetailsContainer           from 'modules/teams/TeamDetailsContainer.js';
import CompetitionsContainer          from 'modules/competitions/CompetitionsContainer.js';
import ExportsContainer               from 'modules/exports/ExportsContainer.js';
import InvitationsContainer           from 'modules/invitations/InvitationsContainer';
import JobsContainer                  from 'modules/jobs/JobsContainer';

import TestPage                       from 'modules/misc/TestPage.js';
import { getUserRoles } from 'reducers/user';


const loginPath      = '/login';

const AppContent = ({isLoggedIn, dispatch, user, navbarMode, showSidebar, showNavbar}) => {
    const [init, setInit] = useState(true)
    const [appDataLoaded, setAppDataLoaded] = useState(false)
    const history = useHistory()
    const location = useLocation()

    const loadAppData = async () => {
        dispatch({type:'SET_LOADING_OVERLAY_ENABLE', enable:true});
        setAppDataLoaded(false)
        const results = await Promise.all([
            DatabaseService.getRoles(),
            DatabaseService.getRoleAllowedRoutes()
        ]).catch((error)=> {
            console.error('loadAppData', error);
        })
        if (results) {
            dispatch({type:'SET_LOADING_OVERLAY_ENABLE', enable:false});
            setAppDataLoaded(true)
        }
    }

    const handleRedirects = (props) => {
        if(!localStorage.companyId &&
            location.pathname!=='/organizations' &&
            isLoggedIn) {
            history.replace('/organizations');
        }
    }

    useEffect(() => {
        if (isLoggedIn) {
            loadAppData()
        } else {
            setAppDataLoaded(true)
        }
        handleRedirects()
    }, [isLoggedIn])


    const initialPath = RoleUtils.getStartingPath(user.roles);
    //return null;
    return (init &&
        <DefaultLayout showNavbar={showNavbar} showSidebar={showSidebar} navbarMode={navbarMode} >
            { appDataLoaded &&
            <Switch>
                <RedirectRoute exact path="/"                    component={LogInContainer}               when={isLoggedIn}  to={initialPath}/>
                <RedirectRoute path={loginPath}                  component={LogInContainer}               when={isLoggedIn}  to={initialPath}/>
                <RedirectRoute path='/forgotpassword'            component={ForgotPasswordContainer}      when={isLoggedIn}  to={initialPath}/>
                
                <ProtectedRoute path="/dashboard"                 component={DashboardContainer}           when={!isLoggedIn} to={loginPath} />
                <ProtectedRoute path="/surveys"                   component={SurveyContainer}              when={!isLoggedIn} to={loginPath} />
                <ProtectedRoute path="/invitations"               component={InvitationsContainer}         when={!isLoggedIn} to={loginPath} />
                <ProtectedRoute path="/rewards"                   component={RewardsContainer}           when={!isLoggedIn} to={loginPath} />
                <ProtectedRoute path="/rewardsV2"                   component={RewardsContainerV2}           when={!isLoggedIn} to={loginPath} />
                <ProtectedRoute path="/marketing"                 component={MarketingContainer}           when={!isLoggedIn} to={loginPath}/>
                <ProtectedRoute path="/goals/new"                 component={CategoryContainer}            when={!isLoggedIn} to={loginPath} cprops={{new: true}} />
                <ProtectedRoute path="/goals/:id"                 component={CategoryContainer}            when={!isLoggedIn} to={loginPath}/>
                <ProtectedRoute path="/goals"                     component={CategoriesContainer}          when={!isLoggedIn} to={loginPath}/>
                <ProtectedRoute path="/pathways/new"              component={PathwayContainer}             when={!isLoggedIn} to={loginPath} cprops={{new: true}} />
                <ProtectedRoute path="/pathways/clone"            component={PathwayContainer}             when={!isLoggedIn} to={loginPath} cprops={{clone: true}} />
                <ProtectedRoute path="/pathways/:id"              component={PathwayContainer}             when={!isLoggedIn} to={loginPath}/>
                <ProtectedRoute path="/objectives"                component={ObjectivesContainer}          when={!isLoggedIn} to={loginPath}/>
                <ProtectedRoute path="/members"                   component={EmployeesContainer}           when={!isLoggedIn} to={loginPath} />
                <ProtectedRoute path="/organizations"             component={CompaniesContainer}           when={!isLoggedIn} to={loginPath}/>
                <ProtectedRoute path="/account"                   component={AccountContainer}             when={!isLoggedIn} to={loginPath}/>
                <ProtectedRoute path="/announcements"             component={AnnouncementsContainer}       when={!isLoggedIn} to={loginPath}/>
                <ProtectedRoute path="/hra"                       component={HraContainer}                 when={!isLoggedIn} to={loginPath}/>
                {/* <RedirectRoute path="/fwa"                       component={FwaContainer}                 when={!isLoggedIn} to={loginPath}/> */}
                <ProtectedRoute path="/reports"                   component={ReportsContainer}             when={!isLoggedIn} to={loginPath}/>
                <ProtectedRoute path="/calendar"                  component={Calendar}                     when={!isLoggedIn} to={loginPath}/>
                <ProtectedRoute path="/riskprofiles"              component={RiskProfilesContainer}        when={!isLoggedIn} to={loginPath}/>
                <ProtectedRoute path="/segments"                  component={SegmentsContainer}            when={!isLoggedIn} to={loginPath}/>
                <ProtectedRoute path="/teams/details/:id"         component={TeamDetailsContainer}         when={!isLoggedIn} to={loginPath} />
                <ProtectedRoute path="/teams"                     component={TeamsContainer}               when={!isLoggedIn} to={loginPath} />
                <ProtectedRoute path="/competitions"              component={CompetitionsContainer}        when={!isLoggedIn} to={loginPath} />
                <ProtectedRoute path="/leaderboards/:id/:teamId"  component={LeaderboardContainer}         when={!isLoggedIn} to={loginPath}  cprops={{withinTeam: true}}/>
                <ProtectedRoute path="/leaderboards/:id"          component={LeaderboardContainer}         when={!isLoggedIn} to={loginPath}/>
                <ProtectedRoute path="/exports"                   component={ExportsContainer}             when={!isLoggedIn} to={loginPath}/>
                <ProtectedRoute path="/jobs"                      component={JobsContainer}                when={!isLoggedIn} to={loginPath}/>
                <RedirectRoute path="/testpage"                  component={TestPage}                     when={!isLoggedIn} to={loginPath}/>
                <RedirectRoute path="*"                          component={PageNotFound}                 when={!isLoggedIn} to={loginPath}/>
            </Switch>
            }
        </DefaultLayout>
    );
}

const mapRolesToProps = (state, ownProps) => {
    return { allowedRoutes : RoleUtils.getPathFilter(getUserRoles(state)) };
}

const ProtectedRoute = connect(mapRolesToProps)(({allowedRoutes, ...props}) => {
    return (
        allowedRoutes.includes('/' + props.path.split('/')?.[1])
        ? <RedirectRoute {...props} />
        : <PageNotFound />
    )
})

const RedirectRoute = ({ component: Component, when, to, preserveSearch, cprops, ...rest }) => {
  return (<Route {...rest} render={props => (
        when ? (
          <Redirect to={{
            pathname: to,
            search: (preserveSearch ? props.location.search : undefined),
            state: { from: props.location }
          }}/>
        ) : (
          <Component {...props} {...cprops}/>
        )
      )}/>
    );
}

const mapAppContentProps = (state, ownProps) => {
    return { isLoggedIn   :state.user.loggedIn,
             showNavbar   :state.navbar.enable,
             showSidebar  :state.sidebar.enable,
             navbarMode   :state.navbar.mode,
             user         :state.user};
}
export default withRouter(connect(mapAppContentProps)(AppContent));
