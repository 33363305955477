import React, { Component } from 'react'
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer'
import DatabaseService from '../../services/DatabaseService';

class SessionTimer extends Component {
    constructor(props) {
        super(props)
        this.idleTimer = null
    }

    render() {
        return (
          <div>
            {this.props.isLoggedIn && <IdleTimer
              ref={ref => { this.idleTimer = ref }}
              element={document}
              onActive={this.onActive}
              onIdle={this.onIdle}
              onAction={this.onAction}
              timeout={1000 * 60 * 10} /> }
          </div>
        )
    }

    // onAction = (e) => {
    //     console.log('user did something', e)
    // }

    // onActive = (e) => {
    //     console.log('user is active', e)
    //     console.log('time remaining', this.idleTimer.getRemainingTime())
    // }

    onIdle = (e) => {
        console.log('user is idle', e)
        DatabaseService.signOut()
        window.location.href = '/login'
    }
}


const mapStateToProps = (state) => {
    return { isLoggedIn   :state.user.loggedIn };
}

export default withRouter(connect(mapStateToProps)(SessionTimer));

