import React, { Component, useState, useEffect, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'underscore';
import {SET_LOADING_OVERLAY_ENABLE} from 'actions/types';
import {setSidebarEnable} from 'actions/sidebar.js';
import DatabaseService from 'services/DatabaseService.js';

import Employees from './Employees.js';
import { getUserRoles } from '../../reducers/user.js';


const MembersContainer = ({dispatch, roles}) => {
    const [loading, setLoading] = useState(false)
    const [members, setMembers] = useState([])
    const [totalMembers, setTotalMembers] = useState()
    const [unregisteredCount, setUnregisteredCount] = useState()
    const [uninvitedCount, setUninvitedCount] = useState()
    const [lastLeadAt, setLastLeadAt] = useState()
    const [columns, setColumns] = useState()
    const [madeColumns, setMadeColumns] = useState(false)
    const [deletedMembersId, setDeletedMembersId] = useState({})
    const [memberToDelete, setMemberToDelete] = useState()
    const [memberToEdit, setMemberToEdit] = useState()
    const [disableMember, setDisableMember] = useState(false)
    const [deleteMemberModalOpen, setDeletMemberModalOpen] = useState(false)
    const [showInviteMembers, setShowInviteMembers] = useState()
    const [isInsuranceCarrier, setIsInsuranceCarrer] = useState()
    const [update, setUpdate] = useState()

    const fileSelector = createRef()
    const downloadLink = createRef()
    const downloadLeadLink = createRef()
    const deleteModalRef = createRef();
    const selectColumnRef = createRef();

    const loadData = async (showSpinner) => {
        setLoading(showSpinner)
        //localStorage.companyId --320 325

        const carrotCompanies = ["320", "325"]

        let result = await DatabaseService.getEmployees({
            'summary.total' : true,
            'summary.unregistered': true,
            'summary.uninvited': true,
            'summary.lastLeadAt': true,
            'columns': true,
            'policyHolder': !carrotCompanies.includes(localStorage.companyId) ? true : false,
            'signUpType': !carrotCompanies.includes(localStorage.companyId) ? true : false
        }).catch(error => {
            console.error(error)
            window.alert('An error occurred - check console');
        })
        let employeeAttributesResult = await DatabaseService.getEmployeeAttributes()
        var policyHolderAttribute = employeeAttributesResult.employeeAttributes.find((employeeAttribute) => {
            return employeeAttribute.name === 'policy_holder'
        })
        setIsInsuranceCarrer(policyHolderAttribute ? true : false)
        if (localStorage.master!=='true') {
            result.columns = result.columns.filter(( obj ) => {
                return obj.key !== 'yearlyClaims' && obj.key !== 'claims';
            })
        }
        setMembers(result.employees)
        setTotalMembers(result.summary.total)
        setUnregisteredCount(result.summary.unregistered)
        setUninvitedCount(result.summary.uninvited)
        setLastLeadAt(result.summary.lastLeadAt)
        setColumns(result.columns)
        setLoading(false)
        setMadeColumns(true)
        return result
    }

    useEffect(() => {
        dispatch(setSidebarEnable(true));
        loadData(true);
    }, [])

    const onShowInviteEmployees = (en) => {
        setShowInviteMembers(en)
        //update on close
        if (!en) {
            setUpdate(!update)
        }
    }

    const onShowEditEmployee = (employee) => {
        setMemberToEdit(employee)
        //update on close
        if (!employee) {
            setUpdate(!update)
        }
    }



    const openDeleteModal = (employee) => {
        setMemberToDelete(employee)
        setDisableMember(false)
        setDeletMemberModalOpen(true)
    }

    const closeDeleteModal = () => {
        setMemberToDelete(null)
        setDisableMember(false)
        setDeletMemberModalOpen(false)
    }

    const onDisableEmployeeChange = (event, data) => {
        setDisableMember(data.checked)
    }

    const onDeleteEmployee = (deleteYes, employeeToDelete) => {
        console.log('Employee', employeeToDelete);

        if(deleteYes && employeeToDelete) {
            closeDeleteModal();
            var employees = members.filter(e => e.id!==employeeToDelete.id);
            var deletedEmployeeIds = {...deletedMembersId, [employeeToDelete.id]:true};
            setMembers(employees)
            setDeletedMembersId(deletedEmployeeIds)
            DatabaseService.delEmployeeById({employeeId: employeeToDelete.id, disableEmployee: disableMember})
                .then(()=> {
                    loadData(false);
                    setUpdate(!update)
                })
                .catch((error)=> {
                    console.error(error)
                    window.alert('An error occurred - check console');
                });
        }
    }

    const onSendEmployeeInvite = async (id) => {
        dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:true});
        await DatabaseService.putEmployeeByIdInvite({employeeId:id})
        .catch((error)=> {
            console.error(error)
            window.alert('An error occurred - check console');
        })
        await loadData(false)
        setUpdate(!update)
        dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
    }

    const onUploadCsv = () => {
        fileSelector.current.click();
    }

    const onFileSelect = (event) => {
        var file = event.target.files[0];
        event.target.value=null;

        var reader = new FileReader();
        reader.onerror= (e) => { window.alert(e)  };
        reader.onload = (e) => {
            dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:true});
            DatabaseService.postEmployees({companyEmployees: {csv: reader.result}})
                .then(()=> {
                    return loadData(false);
                })
                .catch((error)=> {
                    console.error(error)
                    window.alert('An error occurred - check console');
                })
               .then(()=>{
                    dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
               });
        };
        reader.readAsText(file, 'UTF-8');
    }

    const onExportCsv = (selectedList) => {
        var link = downloadLink;
        var output = [];
        var start = 0;
        var columns = "";
        columns = selectedList.join();
        var headers = [];

        const exportCallback = () => {

             return DatabaseService.getEmployeesExport({start: start, format: 'csv', columns: columns})
             .then(resp => {
                    return resp.text()}
             ).then(text => {
                if (navigator && navigator.msSaveBlob) { // IE 10+
                    var exportedFilename = link.current.download;
                    var blob = new Blob([text], { type: 'text/csv;charset=utf-8;' });
                    navigator.msSaveBlob(blob, exportedFilename);
                } else {
                    // var encodedUri = encodeURI("data:text/csv;charset=utf-8," +text);
                    var encodedUri = `data:text/csv;charset=utf-8,${encodeURIComponent(text)}`
                    link.current.setAttribute("href", encodedUri);
                    link.current.click();
                }

            },
            function (err) {
                console.log('Error exporting! ' + err)
            })
        }
        exportCallback();
    }

    const onExportLeadCsv = () => {
        var link = downloadLeadLink;
        var start = 0;

        function exportCallback() {
            return DatabaseService.getLeadsExport({start: start, format: 'csv'})
            .then(resp=> {
                return resp.text();
            }).then(text=> {
                if (navigator && navigator.msSaveBlob) { // IE 10+
                    var exportedFilename = link.current.download;
                    var blob = new Blob([text], { type: 'text/csv;charset=utf-8;' });
                    navigator.msSaveBlob(blob, exportedFilename);
                } else {
                    var encodedUri = encodeURI("data:text/csv;charset=utf-8," +text);
                    link.current.setAttribute("href", encodedUri);
                    link.current.click();
                }
            }, (err) => {
                console.log("Error exporting! " + err);
            })
        }
        exportCallback();
    }

    const onRefresh = () => {
        loadData(false);
    }
        return (
            <Employees
                       loading={loading}
                       columns={columns}
                       totalEmployees={totalMembers}
                       unregisteredCount={unregisteredCount}
                       uninvitedCount={uninvitedCount}
                       lastLeadAt={lastLeadAt}
                       showInviteEmployees={showInviteMembers}
                       madeColumns={madeColumns}
                       employeeToEdit={memberToEdit}
                       roles={roles}
                       deleteEmployeeModalOpened={deleteMemberModalOpen}
                       disableEmployee={disableMember}
                       
                       onShowInviteEmployees={onShowInviteEmployees}
                       onShowEditEmployee={onShowEditEmployee}
                       onUploadCsv={onUploadCsv}
                       onFileSelect={onFileSelect}
                       onExportCsv={onExportCsv}
                       onExportLeadCsv={onExportLeadCsv}
                       onDeleteEmployee={onDeleteEmployee}
                       onSendEmployeeInvite={onSendEmployeeInvite}
                       onRefresh={onRefresh}
                       setFileSelector={fileSelector}
                       setDownloadLink={downloadLink}
                       setDownloadLeadLink={downloadLeadLink}
                       employeeToDelete = {memberToDelete}
                       deleteModalRef = {deleteModalRef}
                       selectColumnRef = {selectColumnRef}
                       openDeleteModal = {openDeleteModal}
                       closeDeleteModal = {closeDeleteModal}
                       onDisableEmployeeChange = {onDisableEmployeeChange}
                       isInsuranceCarrier={isInsuranceCarrier}
                       update={update}
                       />
      );

}

const mapStateToProps = (state, ownProps) => {
    return {
        roles:getUserRoles(state)
    }
}
export default withRouter(connect(mapStateToProps)(withTranslation('employees')(MembersContainer)));
