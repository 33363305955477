import React, {useState, useEffect} from 'react';
import { withTranslation } from 'react-i18next';
import {colors, fontSizes, fonts, spacings, shadow, buttons} from 'style';
import {Card, Icon, Button, Image, Table, Popup} from 'semantic-ui-react';
import {PageSizeBar, PageNavigationBar} from 'components/tables'
import {ContentCard} from 'components/card'
import {TableRow} from 'components/row'
import SortIcon from 'modules/misc/SortIcon.js';
import _ from 'underscore'

const list = ({getData, update, type, fields, actions, searchData}) => {
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [offset, setOffset] = useState(0)
    const [sortField, setSortField] = useState()
    const [asc, setAsc] = useState(true)
    const [limit, setLimit] = useState(50)
    const [loading, setLoading] = useState(false)
    const [selectedFields, setSelectedFields] = useState(fields ? fields.filter(field => field.default) : [])
    const [query, setQuery] = useState()
    const [queryField, setQueryField] = useState()

    const loadData = async () => {
        setLoading(true)
        new Promise(async (resolve) => {
            var additionalParams = {}
            if (sortField) {
                additionalParams = {
                    sortField,
                    sortOrder: asc ? 'ascending' : 'descending'
                }
            }
            if (fields && selectedFields.length) {
                selectedFields.filter(field => field.custom).forEach(field => {
                    additionalParams[field.key] = true
                })
            }
            var data
            if (query && queryField) {
                data = await searchData({
                    start: offset*limit,
                    limit: limit,
                    query,
                    queryField,
                    ...additionalParams
                }, true)
            } else {
                data = await getData({
                    start: offset*limit,
                    limit: limit,
                    ...additionalParams
                }, true)
            }
            setData(data.data)
            setTotal(data.count)
            setLoading(false)
            resolve()
        })
    }

    const search = (query) => {
        setQuery(query)
    }

    const setSearchField = (field) => {
        setQueryField(field)
    }

    const changeLimit = (value) => {
        setLimit(value)
        setOffset(0)
    }

    const sortData = (field) => {
        if (sortField === field) {
            setAsc(asc => !asc)
        } else {
            setSortField(field)
        }
    }

    const changePage = (nextPage) => {
        if (nextPage) {
            setOffset(offset => offset+1)
        } else {
            setOffset(offset => offset-1)
        }
    }

    const toggleField = (field) => {
        setSelectedFields(fields => {
            const index = selectedFields.findIndex(f => f.key === field.key)
            if (index === -1) {
                return [
                    ...fields,
                    field
                ]
            } else {
                return [
                    ...fields.slice(0, index),
                    ...fields.slice(index + 1)
                ]
            }
        })
    }

    useEffect(() => {
        if (selectedFields.filter(field => field.custom).length) {
            loadData()
        }
    }, [selectedFields])

    useEffect(() => {
        if (fields && !selectedFields.length) {
            setSelectedFields(fields.filter(field => field.default))
        }
    }, [fields])

    useEffect(() => {
        loadData()
    }, [limit, offset, update, sortField, asc])


    useEffect(() => {
        setOffset(0)
        loadData()
    }, [query, queryField])

    return (
        <div>
            <PageSizeBar
                limit={total ? limit: null}
                onChangeLimit={changeLimit}
                search={searchData && search}
                fields={fields}
                selectedFields={selectedFields}
                toggleField={toggleField}
                setSearchField={setSearchField}
                searchField={queryField}
            />
            {
                type === 'table' ?
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            {
                                selectedFields.map((column, index) => {
                                    if (column.tooltip) {
                                        return (
                                            <Popup content={column.tooltip} trigger={
                                                <Table.HeaderCell key={index} style={column.sortable !== false && {cursor: 'pointer'} || {}} onClick={()=>column.sortable !== false && sortData(column.key)}>
                                                    {column.name || column.title}
                                                    {column.sortable !== false && <SortIcon style={{float: 'right'}} field={column.key} sortField={sortField} asc={asc} />}
                                                </Table.HeaderCell>
                                            }/>
                                        )
                                    } else {
                                        return (
                                            <Table.HeaderCell key={index} style={column.sortable !== false && {cursor: 'pointer'} || {}} onClick={()=>column.sortable !== false && sortData(column.key)}>
                                                {column.name || column.title}
                                                {column.sortable !== false && <SortIcon style={{float: 'right'}} field={column.key} sortField={sortField} asc={asc} /> }
                                            </Table.HeaderCell>
                                        )
                                    }
                                })
                            }
                            <Table.HeaderCell/>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            data.map((item) => (
                                <TableRow key={item.id} item={item} fields={selectedFields} actions={actions}/>
                            ))
                        }
                    </Table.Body>
                </Table> :
                <Card.Group>
                    {data.map((item) => (
                        <ContentCard key={item.id} item={item} actions={actions}/>
                    ))}
                </Card.Group>
            }
            {
                total ?
                <PageNavigationBar
                    busy={loading}
                    total={total}
                    offset={offset}
                    limit={limit}
                    size={data.length}
                    onNextPage={changePage}
                /> : null
            }
        </div>
    )
}

export const List = withTranslation('list')(list)