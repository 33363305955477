import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';

import {SET_LOADING_SPINNER_ENABLE, SET_LOADING_OVERLAY_ENABLE, ADD_COMPANY} from 'actions/types';

import {setSidebarEnable} from 'actions/sidebar.js';
import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';

import Account from './Account.js';


class AccountContainer extends Component {

    constructor(props){
		super(props);
        this.state = {
            busy:false,
            inviteCode:localStorage.inviteCode,
            inviteCodeChanged: false,
            insuranceCarrier: false
        };
        this.onRefresh = this.onRefresh.bind(this);
        this.onShowAddAdmin = this.onShowAddAdmin.bind(this);
        this.onDeleteAdmin = this.onDeleteAdmin.bind(this);
        this.onChangeInviteCode = this.onChangeInviteCode.bind(this);
        this.onSubmitInformation = this.onSubmitInformation.bind(this);
        this.onRemoveCompany = this.onRemoveCompany.bind(this);
        this.onChangeAdminRole = this.onChangeAdminRole.bind(this)
        this.deleteModalRef = React.createRef();
    }

    componentWillMount() {
        this.props.dispatch(setSidebarEnable(true));
        this.loadData(true);
    }

    loadData(showSpinner) {
        showSpinner && this.setState({busy:true});
        Promise.all([
                DatabaseService.getOrganization({billing:true, welcome:true}),
                DatabaseService.getEmployees({admin:true,limit:100, withRoles: true}),
                DatabaseService.getEmployeeAttributes(),
                DatabaseService.getCompanyRoles().catch(() => Promise.resolve())
            ])
            .then(([organizationResult, employeesResult, employeeAttributesResult, companyRolesResult]) => {
                if (companyRolesResult) {
                    companyRolesResult.data.forEach(role => {
                        role.key = role.id
                        role.value = role.id
                        role.text = role.name
                    })
                    this.setState({
                        companyRoles: companyRolesResult.data
                    })
                }
                var policyHolderAttribute = employeeAttributesResult.employeeAttributes.find((employeeAttribute) => {
                    return employeeAttribute.name === 'policy_holder'
                })
                this.setState({company : organizationResult.company,
                               companyRegistration : organizationResult.company.openRegistration,
                               companyAdmins: employeesResult.employees,
                               companyName: organizationResult.company.name,
                               insuranceCarrier: policyHolderAttribute ? true : false,
                               leadMethod : organizationResult.company.leadMethod,
                               leadTemplate : organizationResult.company.leadTemplate
                              });
            })
            .catch((error)=> {
               console.error(error);
               window.alert('An error occurred - check console');
            })
            .then(()=>{
               showSpinner && this.setState({busy:false});
            });
    }

    onRefresh() {
        this.loadData(false);
    }

    onShowAddAdmin(en) {
        this.setState({showAddAdminModal:en});
    }

    onResetDemo = (event) => {
        var r = window.confirm("Are you sure you want to reset the demo?");
        if (r == true) {
            this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:true});
            DatabaseService.postResetDemo({})
                    .then(()=> {
                        this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
                    })
                    .catch((error)=> {
                        console.error(error)
                        window.alert('Oops! Something went wrong. Please try again.');
                    })
        }
    }

    onChangeRegistration = (event) => {
            this.setState({companyRegistration: !this.state.companyRegistration});
            console.log(this.state.companyRegistration);
    }

    onChangeName = (event) => {
        this.setState({companyName: event.target.value});
    }

    onChangeLeadMethod = (event) => {
        this.setState({leadMethod: event.target.value});
    }

    onChangeLeadTemplate= (event) => {
        this.setState({leadTemplate: event.target.value});
    }

    onChangeInviteCode(event) {
        this.setState({inviteCode:event.target.value, inviteCodeChanged: true});
        console.log(this.state.inviteCodeChanged)
    }

    onRemoveCompany(event) {
        //var r = window.confirm(t('remove_company_confirm'));
        var r = window.confirm("Are you sure you want to remove this organization from your portfolio?");
        if (r == true) {
            DatabaseService.removeOrganization({childCompanyId:this.state.company.id})
                    .then(()=> {
                        localStorage.removeItem('companyId');
                        localStorage.removeItem('inviteCode');
                        this.props.history.push('/organizations');
                    })
                    .catch((error)=> {
                        console.error(error)
                        window.alert('Oops! Something went wrong. Please try again.');
                    })
                    .then(()=>{
                        this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
                    });
        }
    }

    onSubmitInformation(event) {
        event && event.preventDefault();

        this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:true});

        var companyData = {
            code: this.state.inviteCode,
            openRegistration: this.state.companyRegistration,
            companyName: this.state.companyName,
            leadMethod: this.state.leadMethod,
            leadTemplate: this.state.leadTemplate
        }

        Promise.all([
            DatabaseService.updateOrganization(companyData),
            ...(this.state.insuranceCarrier ? DatabaseService.createEmployeeAttributes() : [])
        ]).then(([companyResult, employeeAttributeResult]) => {
            localStorage.inviteCode = this.state.inviteCode;
            this.props.dispatch({
                type: ADD_COMPANY,
                company:companyResult.company
            });
            this.onRefresh();
        }).catch(error => {
            window.alert('An error occurred - check console');
            console.error(error);
        }).then(()=>{
            this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
        });
    }

    onChangeAdminRole(admin, roleId) {
        if (!admin.companyEmployeeRoles.length || admin.companyEmployeeRoles[0] !== roleId) {
            DatabaseService.setEmployeeRole({companyEmployeeId: admin.id, roleId})
            .catch((error)=> {
                console.error(error);
                window.alert('An error occurred - check console');
             }).then(() => {
                 this.loadData(false)
             })
        }
    }

    onDeleteAdmin(deleteYes, admin) {
        if(deleteYes && admin){
        var companyAdmins = this.state.companyAdmins.filter(a => a.id!==admin.id);
        this.setState({companyAdmins:companyAdmins});
        return DatabaseService.removeCompanyAdmin({ companyEmployeeId: admin.id })
            .then(result => {
                this.loadData(false);
                this.deleteModalRef = React.createRef();
            })
            .catch((error)=> {
               console.error(error);
               window.alert('An error occurred - check console');
            })
        }
    }

    onInsuranceCarrier = (event) => {
        var confirmation = window.confirm('Are you sure?\nThis action cannot be reverted, once saved.');

        if (confirmation) {
            this.setState({insuranceCarrier: true});
        }
    }


    render() {
        return (
            <Account busy={this.state.busy}
                {...this.state}
                onShowAddAdmin={this.onShowAddAdmin}
                onResetDemo={this.onResetDemo}
                onDeleteAdmin={this.onDeleteAdmin}
                onRefresh={this.onRefresh}
                onChangeInviteCode={this.onChangeInviteCode}
                onSubmitInformation={this.onSubmitInformation}
                onRemoveCompany={this.onRemoveCompany}
                deleteModalRef = {this.deleteModalRef}
                onChangeRegistration = {this.onChangeRegistration}
                onInsuranceCarrier = {this.onInsuranceCarrier}
                onChangeName = {this.onChangeName}
                onChangeLeadTemplate = {this.onChangeLeadTemplate}
                onChangeLeadMethod = {this.onChangeLeadMethod}
                onChangeAdminRole = {this.onChangeAdminRole}
            />
        );
  }
}

export default withRouter(connect()(withTranslation('account')(AccountContainer)));
