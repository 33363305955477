import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Link} from 'react-router-dom';
import {Input, Form, Icon, Image, Button, Card, Message, Label, Segment, Dropdown, Checkbox, Popup} from 'semantic-ui-react';

import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, fonts, spacings, shadow, buttons} from 'style';
import BillingFormContainer from './BillingFormContainer.js';
import AppStartupFormContainer from './AppStartupFormContainer.js';
import AddAdminModalContainer from './modals/AddAdminModalContainer.js';
import defaultAvatar from 'img/default-avatar.png';
import DeletePromptContainer from 'modules/misc/DeletePromptContainer.js';
import DatabaseService from '../../services/DatabaseService.js';

var style = {
    loaderContainer: {
        height: '100%',
    },
    content: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        minHeight: '100%',
        padding: spacings.base,
        backgroundColor: colors.themeGrey,
    },
    header: {
        ...fonts.h3,
        color: colors.themeDarkerGrey,
    },
    subheader: {
        ...fonts.h4,
        color: colors.themeDarkerGrey,
    },
    field: {
        width: '200px',
        fontSize: fontSizes.bodyL,
        fontWeight: 'bold',
        margin: spacings.base,
        padding: spacings.tiny,
        border: '1px solid ' + colors.themeDarkGrey,
        borderRadius: '4px',
    },
    section: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        backgroundColor: '#fff',
        boxShadow: shadow,
        padding: spacings.base,
    },
    button: {
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.short,
    },
    buttonLong: {
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.long,
    },
    adminCard: {
        width: '250px',
    },
    adminCardExtraContent: {
        display: 'flex',
        flexDirection: 'column'
    },
    adminCardExtraContentSection: {
        display: 'flex'
    },
    roleDropdown: {
        minWidth: 120
    },
    deleteButton: {
        float: 'right',
        padding: '0',
        margin: '0',
        fontSize: '24px',
        color: colors.warningRed,
        backgroundColor: '#fff',
        border: 'none',
        boxShadow: 'none',
    },
    deleteButtonIcon: {
        padding: '0',
        margin: '0',
    },
    warningMessage: {
        display: 'block',
    },

    toggleOpenReg: {
        margin: spacings.micro,
        marginTop: '6px'
    }
};

const Account = ({t,
                  inviteCode,
                  company,
                  companyAdmins,
                  companyRoles,
                  busy,
                  showAddAdminModal,
                  onChangeInviteCode,
                  onSubmitInformation,
                  onShowAddAdmin,
                  onResetDemo,
                  onDeleteAdmin,
                  onRefresh,
                  tiers,
                  onChangeTier,
                  onRemoveCompany,
                  deleteModalRef,
                  onChangeRegistration,
                  insuranceCarrier,
                  onInsuranceCarrier,
                  companyRegistration,
                  inviteCodeChanged,
                  onChangeName,
                  companyName,
                  leadTemplate,
                  onChangeLeadTemplate,
                  leadMethod,
                  onChangeLeadMethod,
                  onChangeAdminRole,
                  roles
                }) => (
    busy ? (
        <div style={style.loaderContainer}>
            <LoadInline centered vcentered />
        </div>
    ) : (
        <div style={style.content}>
            <div style={style.header}>{t('Information')}</div>
            <div style={style.section}>
                {localStorage.master ==='true' &&
                    <Form onSubmit={onSubmitInformation}>
                        <Form.Group>
                                 <Form.Field>
                                    <label>{t('organization_name_prompt')}</label>
                                    <Input defaultValue={companyName} onChange={onChangeName} />
                                </Form.Field>
                            <Form.Field>
                                <label>{t('invite_code_prompt')}</label>
                                <Input value={inviteCode} onChange={onChangeInviteCode}></Input>
                            </Form.Field>
                         <Form.Field>
                             <label>{t('open_registration_prompt')}
                             <Popup
                                trigger={<Icon name='question circle outline' />}
                                content={t('OpenRegistrationInfo').split('\n').map(line => (<p>{line}</p>))}
                                position='right center'
                            />
                            </label>
                             <Checkbox toggle style={style.toggleOpenReg} checked = {companyRegistration} onChange={onChangeRegistration}/>
                        </Form.Field>
                        <Form.Field>
                             <label>{t('insurance_carrier')}
                             <Popup
                                trigger={<Icon name='question circle outline' />}
                                content={t('insurance_carrier_info').split('\n').map(line => (<p>{line}</p>))}
                                position='right center'
                            />
                            </label>
                             <Checkbox
                                toggle
                                style={style.toggleOpenReg}
                                checked = {insuranceCarrier}
                                onChange={onInsuranceCarrier}
                                disabled={insuranceCarrier === true}/>
                        </Form.Field>
                        { localStorage.master=='true' && <Form.Field>
                             <label>{t('lead_method')}
                             <Popup
                                trigger={<Icon name='question circle outline' />}
                                content={t('lead_method_info').split('\n').map(line => (<p>{line}</p>))}
                                position='right center'
                            />
                            </label>
                            <Input value={leadMethod} onChange={onChangeLeadMethod} placeholder="(none}"/>
                        </Form.Field> }
                        { localStorage.master=='true' && <Form.Field>
                             <label>{t('lead_template')}
                             <Popup
                                trigger={<Icon name='question circle outline' />}
                                content={t('lead_template_info').split('\n').map(line => (<p>{line}</p>))}
                                position='right center'
                            />
                            </label>
                            <Input value={leadTemplate} onChange={onChangeLeadTemplate} placeholder="(none}"/>
                        </Form.Field> }
                        </Form.Group>
                        <Form.Group>
                        <Form.Field>
                        <Message style={inviteCodeChanged ? {display: 'block'} : {display: 'none'}} warning>
                            <div>{t('invite_code_not_case_sensitive')}</div>
                            <div>{t('invite_code_change_warning')}</div>
                        </Message>
                        </Form.Field>
                        </Form.Group>
                        <Button type='submit' style={style.button} disabled={!inviteCode}>{t('Save')}</Button>
                        {false && <Form.Field>
                            <label style={style.label}>{t('Tier')}</label>
                            <Dropdown options={tiers}
                                          onChange={onChangeTier}
                                          selection
                                          closeOnChange/>
                        </Form.Field>}
                    </Form> ||
                    <Form>
                        <Form.Group>
                            <Form.Field>
                                <label>{t('invite_code_prompt')}</label>
                                {inviteCode}
                            </Form.Field>
                            <Form.Field>
                                <label>{t('open_registration_prompt')}</label>
                                {company.openRegistration == true ? "Yes" : "No"}
                            </Form.Field>
                        </Form.Group>
                    </Form>
                }
                <br />
                {Boolean(String(company.ownerCompanyId) === localStorage.ownerCompanyId && company.id !== company.ownerCompanyId) &&
                    <Form onSubmit={onRemoveCompany}>
                        <Form.Group>
                            <Form.Field>
                                <label>{t('remove_company_label')}</label>
                            </Form.Field>
                        </Form.Group>
                        <Message style={style.warningMessage} warning>
                            <div>{t('remove_company_warning', {'company':company.name})}</div>
                            <br/>
                            <div><strong>{t('remove_company_warning_2')}</strong></div>
                        </Message>
                        <Button type='submit' style={style.buttonLong}>
                            <Icon style={style.trashIcon}
                              name="trash"
                              title={t('delete_company_tooltip')} />
                            {t('remove_company')}
                        </Button>
                    </Form>
                }
            </div>
            <br />
            <br />

            {
                localStorage.master === 'true' && 
                <div>
                    <div style={style.header}>{t('Billing')}</div>
                    <div style={style.section}>
                        <BillingFormContainer billing={company ? company.companyBilling : null}
                                            onRefresh={onRefresh}/>
                    </div>
                    <br />
                    <br />
                </div>

            }
           


            <div style={style.header}>{t('Admins')}</div>
            <div style={style.section}>
                <Card.Group>
                    {companyAdmins.map(admin => (
                        <Card key={admin.id} style={style.adminCard}>
                            <Image src={Utils.deref(admin, 'userProfile.avatar.url', defaultAvatar)} />
                            <Card.Content>
                              <Card.Header>
                                {admin.fullName}
                              </Card.Header>
                              <Card.Description>
                                  {admin.email}
                              </Card.Description>
                            </Card.Content>
                            <Card.Content extra style={style.adminCardExtraContent}>
                                {(!admin.userProfileId) &&
                                    <span>{t('(Unregistered)')}</span>
                                }
                                <DeletePromptContainer ref = {deleteModalRef} onDeleteConfirmation = {onDeleteAdmin} />
                                <div style={style.adminCardExtraContentSection}>
                                    {
                                        (companyRoles?.length || localStorage.master === 'true') && admin.companyEmployeeRoles &&
                                        <Dropdown
                                            style={style.roleDropdown}
                                            options={companyRoles}
                                            onChange={(e, {value}) => onChangeAdminRole(admin, value)}
                                            value={admin.companyEmployeeRoles.length ? admin.companyEmployeeRoles[0].roleId : 'admin'}
                                            selection
                                            closeOnChange
                                        />
                                    }
                                    <Button style={style.deleteButton} onClick={()=>{deleteModalRef.current.showDeletePrompt(admin)}}>
                                        <Icon style={style.deleteButtonIcon} name='trash' />
                                    </Button>
                                </div>
                            </Card.Content>
                        </Card>
                    ))}
                </Card.Group>
                <br />
                <br />
                <br />
                <Button style={style.button} onClick={onShowAddAdmin}>{t('Add Admin')}</Button>
            </div>
            <br />
            <br />
            <div style={style.header}>{t('App Startup')}</div>
            <div style={style.section}>
                <AppStartupFormContainer welcome={company.companyWelcome} onRefresh={onRefresh}/>
            </div>

            {showAddAdminModal &&
                <AddAdminModalContainer
                                        onClose={()=>onShowAddAdmin(false)}
                                        companyRoles={companyRoles}/>
            }

            { (['128', '343', '344'].indexOf(localStorage.companyId) > -1) && 
            <div>
                <br />
                <br />
                <div style={style.header}>{t('Reset Demo')}</div>
                <div style={style.section}>
                    <Button style={style.button} onClick={onResetDemo}>{t('Reset Demo')}</Button>
                </div>
            </div>
            }

        </div>
    )
);

export default withTranslation('account')(Account);
