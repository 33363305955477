import React, { useState, useEffect, useRef, createRef } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter, useLocation, useHistory} from 'react-router-dom';
import Utils from '../../utils/Utils.js';
import Leaderboard from './Leaderboard.js';
import {initNavbarState, setNavbarTitle} from 'actions/navbar.js';
import {fetchCompetitions} from 'actions/competitions.js';
import {fetchLeaderboardAndRecords, fetchLeaderboard} from 'actions/leaderboards.js';
import {getLeaderboard, getLeaderboardRecords, getLeaderboardRecordsEnd} from 'reducers/leaderboards.js';
import {getName, formatLeaderboardValue} from 'modules/leaderboards/tools.js';
import DatabaseService from 'services/DatabaseService.js';
import {SET_LOADING_OVERLAY_ENABLE} from 'actions/types';

export const EXPORT_TYPES = {
    individual: 0,
    individualWithManualTeam: 1,
}

const LeaderboardContainer = ({dispatch, leaderboardState, match, fetchLeaderboard, userProfileId, teamId, loadData, withinTeam, history}) => {
    const [busy, setBusy] = useState(false)
    const [error, setError] = useState()
    const [column, setColumn] = useState()
    const [leaderboard, setLeaderboard] = useState()
    const [records, setRecords] = useState([])
    const [start, setStart] = useState(0)
    const [total, setTotal] = useState(0)
    const limit = 50
    const scrollContainerRef = useRef()
    const downloadLinkRef = useRef()
    const deleteModalRef = createRef();
    const location = useLocation();
    const [isHovering, setIsHovering] = useState(false);
    const [showExportSelectModalContainer, setShowExportSelectModalContainer] = useState(false)

    const onSelectExportType = (exportType) => {
        const params = {}
        if (exportType === EXPORT_TYPES.individual) {
            params.includeManualTeams = false
        } else if (exportType === EXPORT_TYPES.individualWithManualTeam) {
            params.includeManualTeams = true
        }

        onExportLeaderboardMembers(params)
    }

    useEffect(() => {
        setBusy(true)
        var title = (leaderboard && (leaderboard.teamInfo ? leaderboard.teamInfo.title : leaderboard.title)) || '';
        dispatch(initNavbarState({enable:true, menuEnable:false, backButtonEnable:true, title:title}));
        dispatch({type:'SET_SIDEBAR_ENABLE', enable:false});
        loadLeaderboard()
        return () => {
            leaderboardState.recordsMap = {}
        }
    }, [])

    useEffect(() => {
        setBusy(true)
        loadLeaderboard()
    }, [withinTeam])

    const loadLeaderboard = () => {
        var leaderboardId = match.params.id;
        var teamId = match.params.teamId
        return fetchLeaderboard(leaderboardId, true)
        .then((newLeaderboard) => {
            console.log(newLeaderboard)
            newLeaderboard.teamId = teamId
            if (teamId) {
                newLeaderboard.scope = 'userProfile'
                newLeaderboard.type = 'individual'
            }
            var column = newLeaderboard ? (newLeaderboard.column || 'points') : null;
            setColumn(column)
            setLeaderboard({...newLeaderboard});
        });
    }

    useEffect(() => {
        if (leaderboard) {
            loadPage()
        }
    }, [leaderboard, start])

    const changePage = (nextPage) => {
        if (nextPage) {
            setStart(start => Math.max(0, start + limit))
        } else {
            setStart(start => Math.max(0, start - limit))
        }
    }

    const loadPage = (team) => {
        return loadData(leaderboard, start, limit, team)
            .then((result)=> {
                setTotal(result.total)
                setRecords(result.records)
                setBusy(false)
            })
            .catch((error)=> {
                console.error('ERROR:');
                console.error(error);
                setError(error)
            })
    }

    const getAvatars = (scopeObj) => {
        var objs = [scopeObj];
        if(Array.isArray(scopeObj)) {
            objs = scopeObj;
        }
        return objs.map(o=>(o.avatar!=null ? o.avatar.url : null));
    }

    const isMember = (record) => {
        if(userProfileId==null) {
            return false;
        }
        if(record.userProfiles) {
            var userProfiles = record.userProfiles;
            for(var i = 0; i < userProfiles.length; i++) {
                if(userProfiles[i].id===userProfileId) {
                    return true;
                }
            }
            return false;
        }
        if(record.teamId) {
            return record.teamId===teamId;
        }
        return (userProfileId===record.userProfileId);
    }

    const onClickChange = (record) => {
        if (record && record.teamId) {
            history.push(`${location.pathname}/${record.teamId}`)
        }
    }

    const isClickable = (record) => {
        return Boolean(record && record.team);
    }
    var targetPoints = 0;
    if(leaderboard) {
        if(records && records.length && records[0][column] > targetPoints) {
            targetPoints = records[0][column]
        }
    }

    const onExportLeaderboardMembers = (params) => {
        const link = downloadLinkRef;
        const passedParams = params || {}
        function exportCallback() {

            const params = {
                leaderboardId: leaderboard.id,
                seasonId : leaderboard.seasonId || undefined,
                categoryId : leaderboard.categoryId || undefined,
                challengeId : leaderboard.challengeId || undefined,
                teamId : (leaderboard.team ? leaderboard.team.id : leaderboard.teamId) || undefined,
                scope : leaderboard.scope,
                type : leaderboard.type,
                ...(passedParams)
            }
            return DatabaseService.getLeaderboardMembersExport(params)
            .then(resp => {
                   return resp.text()}
            ).then(text => {
               if (navigator && navigator.msSaveBlob) { // IE 10+
                   const exportedFilename = link.current.download;
                   const blob = new Blob([text], { type: 'text/csv;charset=utf-8;' });
                   navigator.msSaveBlob(blob, exportedFilename);
               } else {
                   const encodedUri = `data:text/csv;charset=utf-8,${encodeURIComponent(text)}`
                   const date = new Date();
                   link.current.download = `${leaderboard.title} - ${date.getFullYear()}-${date.getMonth()}-${date.getDate()}.csv`
                   link.current.setAttribute("href", encodedUri);
                   link.current.click(); 
               }
               
           },
           function (err) {
               console.log('Error exporting! ' + err)
           })
       }
       exportCallback(); 
    }

    const onDeleteMember = (deleteYes, record) => {
        if(deleteYes) {
            dispatch({type:SET_LOADING_OVERLAY_ENABLE, enable:true});
            DatabaseService.updateRecord({userRecordId: record.id, enabled: false})
                .catch(error => {
                    window.alert('An error occurred - check logs');
                    console.error(error);
                })
                .then(()=>{
                    dispatch({type:SET_LOADING_OVERLAY_ENABLE, enable:false});
                    loadLeaderboard();

                });
        }
    }

    const handleMouseOver = (record) => {
        // There's an interesting bug when you use hover and creating subchildren that sometimes cause the mouse out to not fire
        // This should have been resolved by using visibility instead but I put this here and an extra failsafe
        let rs = records.map(r=>{ r.isHovering = false; return r })
        record.isHovering = true
        setRecords(rs)
    };


    const handleMouseOut = (record) => {
        let rs = records.map(r=>{ r.isHovering = false; return r })
        record.isHovering = false
        setRecords(rs)
    };

  

    return (<Leaderboard getName={getName}
                        formatLeaderboardValue={formatLeaderboardValue}
                        getAvatars={getAvatars}
                        leaderboard={leaderboard}
                        records={records}
                        targetPoints={targetPoints}
                        column={column}
                        isMember={isMember}
                        isClickable={isClickable}
                        setScrollContainer={scrollContainerRef}
                        busy={busy}
                        start={start}
                        limit={limit}
                        error={error}
                        total={total}
                        changePage={changePage}
                        onClickChange={onClickChange}
                        onExport={onExportLeaderboardMembers}
                        downloadLinkRef={downloadLinkRef}
                        deleteModalRef={deleteModalRef}
                        onDeleteMember={onDeleteMember}
                        isHovering={isHovering}
                        handleMouseOver={handleMouseOver}
                        handleMouseOut={handleMouseOut}
                        onSelectExportType={onSelectExportType}
                        showExportSelectModalContainer={showExportSelectModalContainer}
                        setShowExportSelectModalContainer={setShowExportSelectModalContainer}
                        />);
}

const mapStateToProps = (state, ownProps) => {
   return{leaderboardState:state.leaderboards}
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadData: (id, start, limit) => dispatch(fetchLeaderboardAndRecords(id, start, limit)),
        fetchLeaderboard: (id, force) => dispatch(fetchLeaderboard(id, force)),
        fetchCompetitions: (params, force) => dispatch(fetchCompetitions(params, force)),
        dispatch: dispatch
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(LeaderboardContainer);
