import {SET_LEADERBOARDS,
        SET_TEAM_CHALLENGE_LEADERBOARDS,
        SET_TEAM_CHALLENGE_INFO,
        ADD_LEADERBOARD,
        ADD_LEADERBOARD_RECORDS,
        ADD_CHALLENGE_RECORDS,
        SET_LEADERBOARD_RECORDS,
        SET_LEADERBOARD_RECORDS_END,
        CLEAR_LEADERBOARD_RECORDS} from 'actions/types';



var recordMapValueInitialState = {records:[], end:false};
var leaderboardsInitialState = {
    list:[], 
    teamChallengeList:[], 
    indexed:{}, 
    isListLoaded:false, 
    recordsMap:{},
    challengeRecordsByLeaderboardId:{},
    teamChallengeInfo:{}};
export default (state = leaderboardsInitialState, action) => {
    switch(action.type) {
        case SET_LEADERBOARDS: 
            return {...state,
                    list:action.leaderboards.map((c)=>(c.id)),
                    indexed: action.leaderboards.reduce((m,c)=>{m[c.id]=c;return m;},{...state.indexed}),
                    isListLoaded:true
                    };
        case SET_TEAM_CHALLENGE_LEADERBOARDS: 
            return {...state,
                    teamChallengeList:action.leaderboards.map((c)=>(c.id)),
                    indexed: action.leaderboards.reduce((m,c)=>{m[c.id]=c;return m;},{...state.indexed}),
                    isTeamChallengeListLoaded:true
                    };
        case ADD_LEADERBOARD: 
            return {...state,
                    indexed: {...state.indexed, [action.leaderboard.id]:action.leaderboard}
                    };
        case ADD_LEADERBOARD_RECORDS:
            var recordsMapValue = state.recordsMap[action.leaderboardId] || recordMapValueInitialState;

            //Calculate rank
            var records = recordsMapValue.records.concat(action.records);
            var existingLength = recordsMapValue.records.length;
           // var rank = existingLength>0 ? recordsMapValue.records[existingLength-1].rank : 0;
            for(var i = existingLength; i < records.length; i++) {
                // if(i == 0 || records[i][action.column] < records[i-1][action.column]) {
                //     rank++;
                // }
                // records[i].rank = rank;
                // rank = i;

                if (!records[i].userProfile.anonymous) {
                    const increment = i == 0 || records[i][action.column] < records[i-1][action.column]
                    records[i].rank = increment
                        ? records[i-1].rank + 1
                        : records[i-1].rank
                }

                
            }

            return {...state, recordsMap:{...state.recordsMap, [action.leaderboardId]:{...recordsMapValue, records:records}}};
        case SET_LEADERBOARD_RECORDS:
            var { records, start } = action
            records[0].rank = start + 1
            for(var i = 1; i < records.length; i++) {
                if (!records[i].userProfile.anonymous) {
                    const increment = i == 0 || records[i][action.column] < records[i-1][action.column]
                    records[i].rank = increment
                        ? records[i-1].rank + 1
                        : records[i-1].rank
                }
            }
            return {...state, recordsMap:{...state.recordsMap, [action.leaderboardId]:{records:records, total: action.total}}};
        case SET_LEADERBOARD_RECORDS_END: 
            var recordsMapValue = state.recordsMap[action.leaderboardId] || recordMapValueInitialState;
            return {...state, recordsMap:{...state.recordsMap, [action.leaderboardId]:{...recordsMapValue, end:true}}};
        case CLEAR_LEADERBOARD_RECORDS: 
            return {...state, recordsMap:{}};
        case ADD_CHALLENGE_RECORDS:
            return {...state,
                    challengeRecordsByLeaderboardId: action.challengeRecords.reduce((m,cr)=>{m[cr.leaderboardId]=cr.challengeRecord;return m;},{...state.challengeRecordsByLeaderboardId})
                    };
        case SET_TEAM_CHALLENGE_INFO: 
            return {...state,
                    teamChallengeInfo:action.teamChallengeInfo
                    };
        default:            
            return state;
    }
}

export const isListLoaded = state => state.isListLoaded;
export const getLeaderboards = state => state.list.map( id => state.indexed[id] );
export const getLeaderboard  = (state, id) => state.indexed[id];
export const getLeaderboardRecords  = (state, id) => (state.recordsMap[id]||recordMapValueInitialState).records;
export const getLeaderboardRecordsEnd  = (state, id) => (state.recordsMap[id]||recordMapValueInitialState).end;
export const isTeamChallengeListLoaded = state => state.isTeamChallengeListLoaded;
export const getChallengeRecordByLeaderboardId = (state, id) => state.challengeRecordsByLeaderboardId[id];
export const getTeamChallengeLeaderboards = state => state.teamChallengeList.map( id => state.indexed[id] ).map(l => ({...l, challengeRecord:getChallengeRecordByLeaderboardId(state, l.id)}));
export const getTeamChallengeInfo = state => state.teamChallengeInfo;
