import React, { Fragment } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';

import {Icon, Button, Dropdown, Message, Popup, Checkbox, Header, Modal} from 'semantic-ui-react';
import moment from 'moment';

import RoleUtils from 'utils/RoleUtils'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, fonts, spacings, shadow, buttons} from 'style';
import SortIcon from 'modules/misc/SortIcon.js';
import InviteEmployeesModalContainer from './modals/InviteEmployeesModalContainer.js';
import DeletePromptContainer from '../misc/DeletePromptContainer.js';
import SelectCSVColumn from '../misc/SelectCSVColumn.js';

import EditEmployeeModalContainer from './modals/EditEmployeeModalContainer.js';
import sampleCSV from 'assets/Optimity-Team-Sample.csv';
import Keys from '../../keys/keys.js';
import {getEmployees} from 'reducers/employees';
import {fetchEmployees, searchEmployees} from 'actions/employees';
import {List} from 'components/list'

var style = {
    loaderContainer: {
        height: '100%',
    },
    content: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        minHeight: '100%',
        padding: spacings.base,
        backgroundColor: colors.themeGrey,
    },
    header: {
        ...fonts.h3,
        color: colors.themeDarkerGrey,
    },
    subheader: {
        ...fonts.h4,
        color: colors.themeDarkerGrey,
    },
    section: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        backgroundColor: '#fff',
        boxShadow: shadow,
        padding: spacings.base,
    },
    button: {
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.base,
    },
    newEmployeesNotice: {
        cursor: 'pointer'
    }
};

const sections = {
    ADD_CONSOLE: 'add console',
    EMPLOYEE_NOTICE: 'employee notice',
    TABLE: 'table',
    EXPORT_CONSOLE: 'export console',
    EXPORT_MEMBERS: 'export members',
    EXPORT_LEADS: 'export leads',
}

const accessMap = {
    sales: [
        sections.EXPORT_CONSOLE,
        sections.EXPORT_MEMBERS,
        sections.EXPORT_LEADS
    ],
    leadsgen: [
        sections.EXPORT_CONSOLE,
        sections.EXPORT_LEADS,
    ],
    admin: [
        sections.EMPLOYEE_NOTICE,
        sections.ADD_CONSOLE,
        sections.TABLE,
        sections.EXPORT_CONSOLE,
        sections.EXPORT_MEMBERS,
        sections.EXPORT_LEADS
    ],
    'corporate wellness': [
        sections.EMPLOYEE_NOTICE,
        sections.ADD_CONSOLE,
        sections.TABLE,
        sections.EXPORT_CONSOLE,
        sections.EXPORT_MEMBERS,
        sections.EXPORT_LEADS
    ],
    'corporate wellness standard': [
        sections.EMPLOYEE_NOTICE,
        sections.TABLE,
        sections.EXPORT_CONSOLE,
        sections.EXPORT_MEMBERS
    ]
}

const Employees = ({t,
                    fetchEmployees,
                    searchEmployees,
                    roles,
                    loading,
                    columns,
                    totalEmployees,
                    unregisteredCount,                
                    uninvitedCount,
                    lastLeadAt,
                    showInviteEmployees,
                    employeeToEdit,
                    onShowInviteEmployees,
                    onShowEditEmployee,
                    onUploadCsv,
                    onExportCsv,
                    onExportLeadCsv,
                    onFileSelect,
                    onSendEmployeeInvite,
                    onRefresh,
                    setFileSelector,
                    setDownloadLink,
                    setDownloadLeadLink,
                    openDeleteModal,
                    selectColumnRef,
                    isInsuranceCarrier,
                    deleteEmployeeModalOpened,
                    onDisableEmployeeChange,
                    disableEmployee,
                    onDeleteEmployee,
                    employeeToDelete,
                    closeDeleteModal,
                    update
                    }) => {
    const checkAccess = (section) => {
        return RoleUtils.checkUIAccess(roles, accessMap, section)
    }
    return (
        loading ? (
            <div style={style.loaderContainer}>
                <LoadInline centered vcentered /> 
            </div>
        ) : (
            <div style={style.content}>
                <div style={style.header}>{t('Members')}</div>
                { localStorage.master === 'true' && Boolean(unregisteredCount > 0 || uninvitedCount > 0) && checkAccess(sections.EMPLOYEE_NOTICE) &&
                    <Message style={style.newEmployeesNotice}
                            warning 
                            onClick={()=>onShowInviteEmployees(true)}>
                        {t('unregistered_employees_warning', {unregisteredCount, uninvitedCount})}
                    </Message>
                }


                <div style={style.section}>
                <SelectCSVColumn ref = {selectColumnRef} onConfirmation={onExportCsv} columns = {columns}/>
                    {checkAccess(sections.ADD_CONSOLE) && 
                        <div>
                            <div style={style.subheader}>{t('Add Members')}</div>
                            <input ref={setFileSelector} type="file" style={{display:'none'}} onChange={onFileSelect} />
                            <a download="optimitysample.csv" href={sampleCSV}>
                                <Button style={style.button}>{t('Sample CSV')}</Button>
                            </a>
                            <Button style={style.button} onClick={onUploadCsv}>{t('Upload CSV')}</Button>                
                            <Button style={style.button} onClick={()=>onShowEditEmployee({})}>{t('Manually')}</Button>
                            <br />
                            <br />
                        </div>
                    }
                    {
                        checkAccess(sections.EXPORT_CONSOLE) &&
                        <div>
                            <div style={style.subheader}>{t('Export Members')}</div>
                            {
                                checkAccess(sections.EXPORT_MEMBERS) &&
                                <Fragment>
                                    <Button style={style.button} disabled={!totalEmployees} onClick={() => {selectColumnRef.current.showPrompt()}}>{t('Export All Members CSV')}</Button>
                                    <a ref={setDownloadLink} style={{display: 'none'}} href="#" download="export.csv"></a>
                                </Fragment>
                            }
                            {
                                localStorage.master === 'true' && checkAccess(sections.EXPORT_LEADS) &&
                                <Fragment>
                                    <Button style={style.button} disabled={!lastLeadAt} onClick={onExportLeadCsv}>{t('Export Leads CSV')}</Button>
                                    <span>{lastLeadAt ? t('Lead added') + ": " + moment(lastLeadAt).format('MMMM D YYYY, h:mm a') : ""}</span>
                                    <a ref={setDownloadLeadLink} style={{display: 'none'}} href="#" download="leads.csv"></a>
                                </Fragment>
                            }
                        </div>
                    }
                    <br />
                    <br />
                    <br />
                    {checkAccess(sections.TABLE) && 
                    <div>
                        <div style={style.subheader}>{t('Database')} ({totalEmployees})</div>
                        <List
                            update={update}
                            getData={fetchEmployees}
                            actions={[
                                {
                                    name: 'pencil',
                                    invoke: (employee)=>onShowEditEmployee(employee)
                                },
                                {
                                    name: 'trash',
                                    invoke: (employee)=>openDeleteModal(employee),
                                    color: colors.warningRed
                                },
                                ...( localStorage.master === 'true' ? [
                                    {
                                        name: 'send',
                                        invoke: (employee)=>onSendEmployeeInvite(employee.id),
                                        color: '#379f07'
                                    }] : []
                                    )
                            ]}
                            type='table'
                            fields={columns}
                            searchData={searchEmployees}
                        />
                    </div>
                    }
                </div>

                <Modal open={deleteEmployeeModalOpened}>
                    <Modal.Header>{t('delete_employee_tooltip')}</Modal.Header>
                    <Modal.Content>
                        <div>{t('delete_employee_modal_text')}</div>
                        <br />
                        {Boolean(localStorage.master === "true") && 
                            <Checkbox onChange={onDisableEmployeeChange} checked={disableEmployee} label={t('disable_employee_checkbox')}/>
                        }
                    </Modal.Content>
                    <Modal.Actions>
                        <Button style={style.btnStyleNo} onClick ={()=>{ closeDeleteModal() }}>No</Button>
                        <Button style={style.btnStyleYes} onClick ={()=>{ onDeleteEmployee(true, employeeToDelete) }}>Yes</Button>
                    </Modal.Actions>
                </Modal>

                {Boolean(showInviteEmployees) &&
                    <InviteEmployeesModalContainer 
                        uninvitedCount={uninvitedCount}
                        unregisteredCount={unregisteredCount}
                        onClose={()=>onShowInviteEmployees(false)} />
                }
                {Boolean(employeeToEdit) &&
                    <EditEmployeeModalContainer
                        isInsuranceCarrier={isInsuranceCarrier}
                        employee={employeeToEdit}
                        onRefresh={onRefresh}
                        onClose={()=>onShowEditEmployee(null)} />
                }
            </div>
        )
    );
}

const mapStateToProps = (state, ownProps) => {
    return {employees: getEmployees(state.employees)};
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchEmployees: (params) => {
            return dispatch(fetchEmployees(params))
        },
        searchEmployees: (params) => {
            return dispatch(searchEmployees(params))
        },
        dispatch: dispatch
    };
};

export default  withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('employees')(Employees)));