import React, { useState, useEffect, createRef, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'underscore';

import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';
import {SET_LOADING_OVERLAY_ENABLE} from 'actions/types';

import {Tab, Button, Modal, Header, Input, Label, Message, Dropdown, Popup, Checkbox, Icon, Search} from 'semantic-ui-react';
import {colors, fontSizes, spacings, shadow, buttons} from 'style';
import ChoosePhotoContainer from 'modules/misc/ChoosePhotoContainer.js';
import styleDef from '../style.js';
import DeletePromptContainer from 'modules/misc/DeletePromptContainer.js'
import { DateTimePicker } from 'react-widgets';
import moment from 'moment'
import RewardCard from 'modules/rewards/V2/RewardCard.js';
import RewardSearchCard from 'modules/rewards/V2/RewardSearchCard.js';
import { cropperDimensions } from 'utils/Constants.js';

const languages = [
    {key:'en',  text:'English',  value:'en'},
    {key:'fr',  text:'Français', value:'fr'},
];

const REWARD_ACTION_TYPES = {
    RAFFLE: "RAFFLE",
    LOYALTY: "LOYALTY",
    VRS: "VRS",
    DIRECT_REDEMPTION: "DIRECT_REDEMPTION",
    CHARITY: "CHARITY",
    EARN: "EARN"
}

const REWARD_TYPES = {
    ITEM : "ITEM",
    DONATION : "DONATION",
    OFFER : "OFFER",
    NAVIGATION: "NAVIGATION",
    RAFFLE : "RAFFLE",
    GEMS: "GEMS",
    VRS_NO_REWARD: "VRS_NO_REWARD",
    VRS_PRIZE: "VRS_PRIZE",
    GIFT_CARD: "GIFT_CARD"
}

const categoryToRewardActionType = {
    charities: REWARD_ACTION_TYPES.CHARITY,
    // contests: "VRS",
    discounts: REWARD_ACTION_TYPES.DIRECT_REDEMPTION,
    gift_cards: REWARD_ACTION_TYPES.DIRECT_REDEMPTION,
    more_offers: REWARD_ACTION_TYPES.EARN,
    other_offers: REWARD_ACTION_TYPES.EARN,
    points: REWARD_ACTION_TYPES.LOYALTY,
    raffles: REWARD_ACTION_TYPES.RAFFLE,
    top_offers: REWARD_ACTION_TYPES.EARN,
}

const categoryToRewardType = {
    charities: REWARD_TYPES.DONATION,
    // contests: "VRS",
    discounts: REWARD_TYPES.OFFER,
    gift_cards: REWARD_TYPES.GIFT_CARD,
    more_offers: REWARD_TYPES.OFFER,
    other_offers: REWARD_TYPES.OFFER,
    points: REWARD_TYPES.OFFER,
    raffles: REWARD_TYPES.RAFFLE,
    top_offers: REWARD_TYPES.OFFER,
}

const categoryDefaults = {
    contests: {
        hiddenFields: ['rewardOptionQuantity'],
    },
    raffles: {
        template: 'raffle_template',
        hasStepper: true,
        maxQuantityPerUser: null,
        hiddenFields: ['sku', 'rewardOptionQuantity'],
        disabledFields: ['maxQuantityPerUser', 'hasStepper', 'hasProgressBar']
    },
    offers: {
        template: 'Offer Redemption Email Template',
        maxQuantityPerUser: null,
        hiddenFields: ['sku', 'rewardOptionQuantity'],
        disabledFields: ['hasStepper', 'hasProgressBar']
    },
    charities: {
        hasProgressBar: true,
        hasStepper: true,
        template: 'charity_template',
        maxQuantityPerUser: null,
        hiddenFields: ['sku'],
        disabledFields: ['maxQuantityPerUser', 'hasStepper', 'hasProgressBar']
    },
    points: {
        hiddenFields: ['sku', 'rewardOptionQuantity'],
    },
    gift_cards: {
        hiddenFields: ['rewardOptionQuantity'],
        disabledFields: ['maxQuantityPerUser', 'hasStepper', 'hasProgressBar', /*'asset' uncomment this to disable tile editing */ ]
    }
}

categoryDefaults.discounts = categoryDefaults.offers
categoryDefaults.more_offers = categoryDefaults.offers
categoryDefaults.other_offers = categoryDefaults.offers
categoryDefaults.top_offers = categoryDefaults.offers

const rewardFields = ['quantity', 'currency', 'isPublic', 'maxQuantityPerUser', 'brand', 'sku', 'logoAsset', 'bannerAsset', 'description', 'offer', 'terms', 'termsUrl', 'template', 'providerActivity']

const currencies = [
    {key:'cad',  text:'CAD', value:'CAD'},
    {key:'usd',  text:'USD',  value:'USD'},
]

const priorities = [
    {key:null,  text:'None', value:null},
    {key:'high',  text:'High', value:0},
    {key:'medium',  text:'Medium',  value:1},
    {key:'low',  text:'Low',  value:2},
]

const schedules = [
    {key:null,  text:'None', value:null},
    {key:'daily',  text:'Daily', value:'DAILY'},
    {key:'weekly',  text:'Weekly',  value:'WEEKLY'},
    {key:'monthly',  text:'Monthly',  value:'MONTHLY'},
]

const recurrenceTypeToSchedule = {
    1: 'DAILY',
    2: 'WEEKLY',
    3: 'MONTHLY'
}

const providerActivityRewardActions = [
    {key:null,  text:'None', value:null},
    {key:'click',  text:'Click', value:'CLICK'},
    {key:'referral',  text:'Referral',  value:'REFERRAL'},
    {key:'rewarded_referral',  text:'Rewarded Referral',  value:'REWARDED_REFERRAL'},
]

const providerActivityActionTypes = [
    {key:null,  text:'None', value:null},
    {key: 'default',  text:'Default', value: 'default'},
    {key:'tab',  text:'Tab', value:'tab'},
    {key:'external',  text:'External',  value:'external'},
    {key:'deeplink',  text:'Deep link',  value:'deeplink'},
]

const chevronIcon = {
    borderStyle: 'solid',
    borderWidth: '0.25em 0.25em 0 0',
    display: 'inline-block',
    height: '1rem',
    width: '1rem',
    transform: 'rotate(45deg)'
}

var style = {
    ...styleDef,
    saveButton: {
        ...buttons.short,
        marginLeft: spacings.small,
        marginRight: spacings.small,
    },
    updateButton: {
        ...buttons.short,
        float: 'right',
        marginLeft: spacings.small,
        marginRight: spacings.small,
    },
    deleteButton: {
        ...buttons.short,
        marginLeft: spacings.small,
        marginRight: spacings.small,
        color: colors.warningRed,
        backgroundColor: '#fff',
    },
    uploadButton: {
        ...buttons.short,
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
    },
    imagePreview: {
        display: 'block',
        boxShadow: shadow,
        borderRadius: '9px',
        width: '200px',
        height: '200px',
        objectFit: 'contain',
        margin: spacings.small,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    textarea: {
        resize: 'none',
        height: '100px',
        width: '100%',
        borderRadius: '4px',
        border: '1px solid rgba(34, 36, 38, 0.15)',
    },
    columns: {
    },
    column: {
        display: 'inline-block',
        width: '50%',
    },
    star: {
        color:colors.themeBlue,
        position: 'relative',
        top: '2px',
    },
    
    charactersLeftDesc: {
        float : 'right',
        marginBottom: '10px'
    },
    datePicker: {
        display: 'inline-block',
        // margin: "1rem"
    },
    chevronIcon,
    chevronIconDown: {
        ...chevronIcon,
        transform: 'rotate(135deg)',
    },
    centerAlign: {
        textAlign: 'center'
    },
    flex: {
       display: "flex", 
       alignItems: "center", 
       gap: "1rem",
       margin: "1rem 0"
    },
    relative: {
        position: 'relative'
    },
    IconOnImage: {
        position: 'absolute',
        top: 0,
        right: '5rem',
        fontSize: '2rem',
        // color: 'red',
        cursor: 'pointer'
    },
    gemIcon: {
        color: colors.pointsBlue
    },
    rewardCardContainer: {
        display: 'inline-block',
        margin: '1rem'
    },
    addButton: {
        color: colors.themeTeal,
        fontSize: '2rem',
        // margin: '0 1rem',
        cursor: 'pointer'
    },
    warning: {
        color: colors.warningRed
    }
    
};

const RewardLocalePane = ({t, data, valueChange, onChange, isDisabled, isReadOnly}) => (
    <div>
        <br />
        <div>Reward Listing {t('title_prompt')}</div>
        <Popup trigger={
            <textarea  value={data ? data.rewardActionTitle : ''} 
            name= {'rewardActionTitle'}
            placeholder = {t('title_prompt')}
            onChange={onChange}
            onFocus={onChange}
            maxLength = "50"
            disabled={isReadOnly}
            />
        }
            position = 'right center'
            content = {(50-valueChange) + ' characters left'}
            on='focus' />
        <br />
        <br />
        <div>Reward Listing {t('subtitle_prompt')}</div>
        <Popup trigger={
            <textarea  value={data ? data.rewardActionSubtitle : ''} 
            name= {'rewardActionSubtitle'}
            placeholder = {t('subtitle_prompt')}
            onChange={onChange}
            onFocus={onChange}
            maxLength = "100"
            disabled={isReadOnly}
            />
        }
            position = 'right center'
            content = {(100-valueChange) + ' characters left'}
            on='focus' />
        <br />
        <br />
        <div>Reward Details {t('title_prompt')}</div>
        <Popup trigger={
            <textarea  value={data ? data.title : ''} 
            name= {'title'}
            placeholder = {t('title_prompt')}
            onChange={onChange}
            onFocus={onChange}
            maxLength = "50"
            disabled={isReadOnly}
            />
        }
            position = 'right center'
            content = {(50-valueChange) + ' characters left'}
            on='focus' />
        <br />
        <br />
        <div>{t('description_prompt')}</div>
        <Popup trigger={
            <textarea 
            name = {'description'}
            placeholder ={t('description_prompt')}
            style={style.textarea}
            value={data ? data.description : ''} 
            onChange={onChange}
            onFocus={onChange}
            maxLength = "2000" 
            disabled={isDisabled('description')}
            />
        }
            position = 'top right'
            content = {(2000-valueChange) + ' characters left'}
            on='focus' />
        <br />
        <br />
        <div>{t('offer_prompt')}</div>
        <Popup trigger={
                <Input  value={data ? data.offer : ''} 
                name= {'offer'}
                placeholder = {t('offer_prompt')}
                onChange={onChange}
                onFocus={onChange}
                maxLength = "50"
                disabled={isDisabled('offer')}
                />
            }
            position = 'right center'
            content = {(50-valueChange) + ' characters left'}
            on='focus'
            />
    </div>
);


const OptionalRewardLocalePane = ({t, data, valueChange, onChange, rewardCategory, isDisabled}) => (
    <div>
        <br />
        <div>{t('brand_prompt')}</div>
        <Popup trigger={
            <Input  value={data ? data.brand : ''}
            name= {'brand'}
            disabled={isDisabled('brand')}
            placeholder = {t('brand_prompt')}
            onChange={onChange}
            onFocus={onChange}
            maxLength = "200"/>
        }
            position = 'right center'
            content = {(200-valueChange) + ' characters left'}
            on='focus' />
        <br />
        <br />
        <div>{t('template_prompt')}</div>
        <Popup trigger={
            <Input  value={data ? data.template : ''}
            name= {'template'}
            placeholder = {t('template_prompt')}
            onChange={onChange}
            onFocus={onChange}
            maxLength = "50"
            disabled={isDisabled('template')}
            />
        }
            position = 'right center'
            content = {(50-valueChange) + ' characters left'}
            on='focus' />
        <br/>
        <br/>
        <div>{t('terms_prompt')}</div>
        <Popup trigger={
            <textarea 
            name = {'terms'}
            placeholder ={t('terms_prompt')}
            style={style.textarea}
            value={data ? data.terms : ''} 
            onChange={onChange}
            onFocus={onChange}
            maxLength = "4000" 
            disabled={isDisabled('terms')}
            />
        }
            position = 'top right'
            content = {(4000-valueChange) + ' characters left'}
            on='focus' />

        <br/>
        <br />
        <div>{t('terms_url_prompt')}</div>
        <Popup trigger={
            <Input  value={data ? data.termsUrl : ''}
                name= {'termsUrl'}
                placeholder = {t('terms_url_prompt')}
                onChange={onChange}
                onFocus={onChange}
                maxLength = "200"
                disabled={isDisabled('termsUrl')}
                />
            }
            position = 'right center'
            content = {(200-valueChange) + ' characters left'}
            on='focus'
            />
        <br/>
        <br/>
    </div>
);


const ProviderActivityLocalePane = ({t, data, valueChange, onChange, isDisabled}) => (
    <div>
        <br />
        <Popup trigger={
            <Input  value={data ? data.providerActivityBaseURL : ''} 
            name= {'providerActivityBaseURL'}
            placeholder = {t('linkout')}
            disabled={isDisabled('providerActivity')}
            onChange={onChange}
            maxLength = "400"/>
        }
            position = 'right center'
            content = {(400-valueChange) + ' characters left'}
            on='focus' />        
    </div>
);

const EditRewardV2ModalContainer = ({ t, dispatch, resourceScaler, rewardAction: rewardActionParam, onClose, onRefresh, rewardCategory, categories}) => {
    const deleteModalRef = createRef()
    const fileSelector = createRef()
    const [locale, setLocale] = useState('en')
    const [locales, setLocales] = useState({
        en:{brand: '', rewardActionTitle: '', rewardActionSubtitle: '', title:'', subtitle:'', description:'', template: '', terms: '', termsUrl: '', offer: '', providerActivityBaseURL: ''},
        fr:{brand: '', rewardActionTitle: '', rewardActionSubtitle: '', title:'', subtitle:'', description:'', template: '', terms: '', termsUrl: '', offer: '', providerActivityBaseURL: ''}
      })
    const [currency, setCurrency] = useState('CAD')
    const [priority, setPriority] = useState(rewardActionParam?.priority)
    const [catalog, setCatalog] = useState()
    const [schedule, setSchedule] = useState()
    const [codesFile, setCodesFile] = useState()
    const [codes, setCodes] = useState()
    const [asset, setAsset] = useState(rewardActionParam?.asset)
    const [logoAsset, setLogoAsset] = useState()
    const [bannerAsset, setBannerAsset] = useState()
    const [companyId, setCompanyId] = useState(rewardActionParam?.companyId)
    const [valueChange, setValueChange] = useState(0)

    const [category, setCategory] = useState(rewardCategory)
    const [rewardType, setRewardType] = useState()
    const [isPublic, setIsPublic] = useState(false)
    const [isAuction, setIsAuction] = useState(!!rewardActionParam?.isAuction)
    
    const [preferredLanguage, setPreferredLanguage] = useState(rewardActionParam?.locale || 'en')
    const [uploadPhotoProperty, setUploadPhotoProperty] = useState()
    const [hasStepper, setHasStepper] = useState()
    const [hasProgressBar, setHasProgressBar] = useState()
    
    const [hasCode, setHasCode] = useState(false)
    const [maxQuantityPerUser, setMaxQuantityPerUser] = useState()
    const [quantity, setQuantity] = useState()
   
    const [rewardOptions, setRewardOptions] = useState([])
    const [rewardAction, setRewardAction] = useState()
    const [availableAt, setAvailabeAt] = useState(rewardActionParam?.availableAt)
    const [endsAt, setEndsAt] = useState(rewardActionParam?.endsAt)
    const update = Boolean(rewardActionParam?.id)
    const [isLoading, setIsLoading] = useState(true)

    const [createChoice, setCreateChoice] = useState()
    const [collapseOptionalConfig, setCollapseOptionalConfig] = useState(true);
    const [inventoryRewards, setInventoryRewards] = useState([])
    const [segments, setSegments] = useState([])
    const [search, setSearch] = useState()
    const [searching, setSearching] = useState(false)
    const [searchSegment, setSearchSegment] = useState()
    const [selectedInventoryReward, setSelectedInventoryReward] = useState()
    const [segment, setSegment] = useState(rewardActionParam?.team)
    const [modelPos, setModalPos] = useState({ top: 'auto' })
    const [showPage, setShowPage] = useState(update ? 'edit' : 'createSelection')
    const [categoryAnalytics, setCategoryAnalytics] = useState({})
    const [providerActivity, setProviderActivity] = useState()
    const [rewardProvider, setRewardProvider] = useState()
    const [rewardProviderValue, setRewardProviderValue] = useState()
    const [isReadOnly, setIsReadOnly] = useState(false)
    

    useEffect(() => {
        const categoryAnalyticsObj = {}
        categories.forEach(category => {
            categoryAnalyticsObj[category.key] = category.text
        })
        setCategoryAnalytics(categoryAnalyticsObj)
    }, [])

    useEffect(() => {
        if (categoryToRewardActionType.hasOwnProperty(category)){
            setRewardType(category)
        } else {
            setRewardType('')
        }
    }, [category])

    useEffect(() => {
        const newRewardOptions = [addNewRewardOption({resources: 0})]
        if(rewardActionParam) { // update
            setRewardAction({
                ...rewardActionParam,
                rewardCategory,
                type: categoryToRewardActionType[rewardType],
                asset: asset?.dataURL,
                enabled: true
            })
            let firstReward = null
            let firstRewardOption = null
            if (rewardActionParam.rewardOptions && rewardActionParam.rewardOptions.length  > 0) {
                firstRewardOption = rewardActionParam.rewardOptions[0]
                firstReward = firstRewardOption.reward
            }

            setLocales(locales => {
                if(rewardActionParam.locales) { // Reward Action locales
                    rewardActionParam.locales.forEach(translation => {
                        locales[translation.locale] = {
                            ...locales[translation.locale],
                            rewardActionTitle: translation.title || '',
                            rewardActionSubtitle: translation.subtitle || '',
                        }
                    })
                    rewardActionParam.rewardOptions.forEach(option => {
                        option.reward.locales.forEach(translation => {
                            locales[translation.locale].title = translation.title || ''
                            locales[translation.locale].offer = translation.offer || ''
                            locales[translation.locale].description = translation.description || ''

                            // Optional
                            if(translation.template)
                                locales[translation.locale].template = translation.template
                            if(translation.terms)
                                locales[translation.locale].terms = translation.terms
                            if (translation.termsUrl)
                                locales[translation.locale].termsUrl = translation.termsUrl
                            if (translation.brand)
                                locales[translation.locale].brand = translation.brand
                            if (translation.sku) {
                                if (_.isObject(option.reward.sku)) {
                                    option.reward.sku[translation.locale] = translation.sku
                                } else {
                                    option.reward.sku = {[translation.locale] : translation.sku}
                                }
                            }

                            // Update from admin panel not supported as of now, set these only if they are present in the existing reward
                            if (translation.offerUrl)
                                locales[translation.locale].offerUrl = translation.offerUrl   
                        })

                        if (option.reward.providerActivity) {
                            option.reward.providerActivity.locales.forEach(translation => {
                                locales[translation.locale].providerActivityBaseURL = translation.baseURL || ''
                            })
                        }
                    })
                }
                return locales
            })
            setRewardOptions(() => firstRewardOption ? rewardActionParam.rewardOptions : newRewardOptions)
            setSchedule(() => {
                const {rewardSchedule} = rewardActionParam
                if (!rewardSchedule) return undefined
                return ({
                    ...rewardSchedule,
                    recurrenceType: recurrenceTypeToSchedule[rewardSchedule.recurrenceType]
                })
            })
            setIsPublic(firstReward?.companyId == null)
            setBannerAsset(firstReward?.banner)
            setLogoAsset(firstReward?.asset)
            setHasStepper(firstRewardOption?.hasStepper || categoryDefaults[rewardCategory]?.hasStepper)
            setHasProgressBar(rewardActionParam.hasProgressBar || categoryDefaults[rewardCategory]?.hasProgressBar)
            setHasCode(!!firstReward?.hasCode)
            setMaxQuantityPerUser(firstReward?.maxQuantityPerUser || categoryDefaults[rewardCategory]?.maxQuantityPerUser || null)
            setQuantity(firstReward?.quantity != null ? firstReward.quantity : categoryDefaults[rewardCategory]?.quantity || null)
            setCatalog(firstReward?.catalog)
            setProviderActivity(() => {
                if (!firstReward?.providerActivity) return undefined
                return ({
                    ...firstReward.providerActivity,
                    rewardAction: firstReward.providerActivity.rewardAction.toUpperCase(),
                    sponsorTag: firstReward.providerActivity.sponsor?.name
                })
            })
            setRewardProvider(() => {
                if (!firstReward?.rewardProvider) return undefined
                return firstReward.rewardProvider
            })
            setRewardProviderValue(firstReward?.rewardProviderValue)

            const hasReadOnlyReward = (rewardActionParam.rewardOptions || []).some(option => option.reward.readOnly == true)
            if (hasReadOnlyReward && !Boolean(localStorage.master === 'true'))
                setIsReadOnly(true)

        } else { // create
            setRewardAction({
                rewardOptions: [],
                rewardCategory,
                enabled: true,
            })
            setRewardOptions(newRewardOptions)
            setLocales(locales => {
                Object.keys(locales).forEach(locale => {
                    locales[locale] = {
                        ...locales[locale],
                        template: categoryDefaults[rewardCategory]?.template || ''
                    }
                })
                return locales
            })
            setHasStepper(categoryDefaults[rewardCategory]?.hasStepper)
            setHasProgressBar(categoryDefaults[rewardCategory]?.hasProgressBar)
            setMaxQuantityPerUser(categoryDefaults[rewardCategory]?.maxQuantityPerUser)
            setQuantity(categoryDefaults[rewardCategory]?.quantity || null)
        }
        setIsLoading(false)
    }, [rewardActionParam, rewardCategory])

    // update all common values across options
    useEffect(()=>{
        setRewardOptions(rewardOptions => rewardOptions
            .map(rewardOption => ({
                ...rewardOption,
                availableAt,
                endsAt,
                hasStepper,
                rewardId: selectedInventoryReward?.id, 
                reward: {
                    ...rewardOption.reward,
                    public: isPublic,
                    currency,
                    asset: logoAsset?.dataURL,
                    bannerAsset: bannerAsset ? bannerAsset?.dataURL : null,
                    ...spreadLocales(locales),
                    availableAt,
                    expiresAt: endsAt,
                    priority: priority || 0,
                    catalog,
                    type: categoryToRewardType[rewardType],
                    analyticsCategory: categoryAnalytics[category],
                    quantity,
                    maxQuantityPerUser,
                    hasCode,
                    providerActivity: providerActivity 
                    ? {
                        ...providerActivity,
                        baseURL: spreadLocales(locales).providerActivityBaseURL
                    }
                    : undefined,
                    rewardProviderId: rewardProvider?.id,
                    rewardProviderValue,
                }
            }))
        )
    }, [rewardProvider, rewardProviderValue, availableAt, endsAt, currency, asset, bannerAsset, logoAsset, locales, category, priority, isPublic, hasStepper, hasCode, quantity, maxQuantityPerUser, catalog, selectedInventoryReward, providerActivity, rewardType])

    
    useEffect(()=>{
        if(rewardAction)
            setRewardAction({
                ...rewardAction,
                teamId: segment?.id || null,
                companyId,
                ...spreadLocales(locales),
                rewardCategory: category,
                type: categoryToRewardActionType[rewardType],
                asset: asset?.dataURL,
                availableAt,
                endsAt,
                priority,
                rewardSchedule: schedule,
                hasProgressBar
            })
    }, [availableAt, endsAt, asset, locales, category, priority, schedule, hasProgressBar, segment, rewardType])

    const spreadLocales = (locales) => {
        // copy en translations to en_ca
        locales.en_ca = {...locales.en}

        const result = {};
        Object.entries(locales).forEach(([locale, translation]) => {
            Object.entries(translation).forEach(([key, value]) => {
                if(!result.hasOwnProperty(key))
                    result[key] = {[locale]: value || null}
                else if (value)
                    result[key][locale] = value || null
            })
        })

        return result;
    }

    const isDisabled = (key) => {
        if (update && isReadOnly && !Boolean(localStorage.master === 'true'))
            return true

        if (categoryDefaults[rewardCategory]?.disabledFields?.includes(key))
            return true
        
        if (key == 'sku' && Boolean(localStorage.master  === 'true'))
            return false

        if (key == 'sku' && !Boolean(localStorage.master === 'true'))
            return true

        // disable global reward editing
        if (update && isPublic && rewardFields.includes(key))
            return true
      
        // create from existing
        if (!update && selectedInventoryReward && rewardFields.includes(key))
            return true
        
        // create new - don't allow global rewards for other companies admins
        if (!update && key == 'isPublic' && !Boolean(localStorage.master  === 'true'))
            return true

        return false
    }

    const onSubmit = async (event) => {
        event && event.preventDefault();
        const method = update ? 'updateRewardAction' : 'addRewardAction';

        const rewardActionData = {
            ...rewardAction,
            isAuction: !!isAuction,
            ...spreadLocales(locales),
            ...(schedule && {
                rewardSchedule : {
                    ...schedule,
                    recurrenceType: recurrenceTypeToSchedule[schedule.recurrenceType] || schedule.recurrenceType
                }
            }),
            rewardActionCompanyId: rewardAction.companyId,
            rewardOptions: selectedInventoryReward || (update && !companyId)
                ? rewardOptions.map(option => ({...option, reward: null})) // prevents any change on reward
                : rewardOptions
        }

        dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:true});
        try {
            const result = await DatabaseService[method](rewardActionData)
            if(codes) {
                const rewardId = update ? rewardOptions[0].rewardId : result.rewardOptions[0].rewardId
                await DatabaseService.uploadRewardCodes({
                    rewardId, 
                    codes
                })
            }
            onRefresh && onRefresh();
            onClose();
        } catch(err) {
            window.alert(err.message || 'An error occurred - check logs');
            console.error(err)
        } finally {
            dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
        }
    }

    const onDelete = async (deleteYes) => {
        if(deleteYes){
            try {
                await DatabaseService.removeRewardAction({rewardActionId: rewardAction.id})
                onRefresh && onRefresh();
                onClose();
            } catch(err) {
                window.alert(err.message || 'An error occurred - check logs');
                console.error(err)
            } finally {
                dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
            }
        }
    }

    const isValid = () => {
        var selectedLocale = locales[locale];
        return Boolean(selectedLocale) &&
                Boolean(selectedLocale.title) &&
                Boolean(category) &&
                Boolean(asset) &&
                Boolean(currency)
    }

    const onChangePreferredLanguage = (event, {value}) => {
        setPreferredLanguage(value)
    }

    const onChangeCategory = (event, {value}) => {
        setCategory(value)
    }

    const onChangeRewardType = (event, {value}) => {
        setRewardType(value)
    }

    const onChangeCurrency = (event, {value}) => {
        setCurrency(value)
    }

    const onChangePriority = (event, {value}) => {
        setPriority(value)
    }

    const sanitizeValue = (key, value) => {
        if (key === 'price') {
            value = Math.max(0,value) * 100
        }

        if (key === 'sku') {
            const localeMap = _.indexBy(rewardOptions[0].reward.locales, 'locale')
            value = {
                ...(localeMap.en && {en: value}),
                ...(localeMap.en_ca && {en_ca: value}),
                ...(localeMap.fr && {fr: value}) // Currently, Force english locale to fr
            }
        }

        return value
    }

    const onUpdateReward = (updatedRewardOption, key) => (event) => {
        const value = sanitizeValue(key, event.target.value);

        setRewardOptions(rewardOptions => rewardOptions
            .map((rewardOption, index) => {
                if (rewardOption.id === updatedRewardOption.id) {
                    return {
                        ...rewardOption,
                        ...(key === 'resources' && {[key]: value}),
                        reward: {
                            ...rewardOption.reward,
                            [key]: value
                        }
                    }
                }
                return rewardOption
            }))
    }

    const updateAllRewards = (key) => (event) => {
        const value = sanitizeValue(key, event.target.value);

        setRewardOptions(rewardOptions => rewardOptions
            .map((rewardOption, index) => {
                return {
                    ...rewardOption,
                    reward: {
                        ...rewardOption.reward,
                        [key]: value
                    }
                }
            })
        )
    }

    const onUpdateRewardOption = (updatedRewardOption, key) => (event) => {
        var value = event.target.value;

        if (key === 'visible') {
            value = !updatedRewardOption.visible
        }

        setRewardOptions(rewardOptions => rewardOptions
            .map((rewardOption, index) => {
                if ((rewardOption.id != null && rewardOption.id === updatedRewardOption.id)
                || index === updatedRewardOption.index) {
                    return {
                        ...rewardOption,
                        [key]: value
                    }
                }
                return rewardOption
            }))
    }

    const addNewRewardOption = (config) => {
        const newRewardOption = {
            index: 0, // Temp key to be able to update new options
            availableAt,
            endsAt,
            resources: 0,
            enabled: true,
            visible: true,
            hasStepper,
            quantityBlock: 1,
            ...config,
            reward: {
                currency: 'CAD',
                asset: logoAsset,
                bannerAsset,
                type: categoryToRewardType[rewardType],
                ...spreadLocales(locales),
                enabled: true,
                maxQuantityPerUser: 1,
                resources: 0,
                price: 0,
                priority: priority || 0,
                catalog,
                availableAt,
                expiresAt: endsAt,
                analyticsCategory: categoryAnalytics[category],
                hasCode,
                providerActivityId: providerActivity?.id,
                sku: {},
                ...(config.reward || {})
            }
        }

        return newRewardOption
    }

    const handleAddRewardOption = () => {

        setRewardOptions(rewardOptions => ([
            ...rewardOptions,
            addNewRewardOption({
                resources: 0,
                index: rewardOptions.length
            })
        ]))
    }

    const onChangeMaxQuantityPerUser = () => {
        if (!isDisabled('maxQuantityPerUser'))
            setMaxQuantityPerUser(maxQuantityPerUser ? null : 1)
    }

    const onChangeHasCode = () => {
        if (!isDisabled('hasCode'))
            setHasCode(!hasCode)
    }

    const onChangeHasStepper = () => {
        if (!isDisabled('hasStepper'))
            setHasStepper(!hasStepper)
    }

    const onChangeHasProgressBar = () => {
        if (!isDisabled('hasProgressBar'))
            setHasProgressBar(!hasStepper)
    }

    const onChangePublic = () => {
        if (!isDisabled('isPublic'))
            setIsPublic(!isPublic)
    }

    const onChangeAction = () => {
        if (!isDisabled('isAuction'))
            setIsAuction(!isAuction)
    }

    const onChangeQuantity = (event) => {
        var value = event.target.value || null;
        setQuantity(Math.max(0,value))
    }

    const onChangeLocales = (event, locale) => {
        var key = event.target.name;
        var value = event.target.value;
        setValueChange(value.length)
        setLocales(locales => {
            let newLocales = {...locales}
            newLocales[locale][key] = value
            return newLocales
        })
    }

    const onChangeSchedule = data => {
        if (data.hasOwnProperty('recurrenceType') && !data.recurrenceType)
            setSchedule(null)
        else {
            setSchedule({
                ...schedule,
                ...data,
                ...(!schedule?.timeZone && {timeZone: moment.tz.guess(true)})
            })
        }
    }

    const onChangeProviderActivity = data => {
        if (!isDisabled('providerActivity')) {
            if (data.hasOwnProperty('rewardAction') && !data.rewardAction)
                setProviderActivity(null)
            else {
                setProviderActivity({
                    ...providerActivity,
                    ...data,
                })
            }
        }
    }

    const onChangeRewardProvider = data => {
        if (!isDisabled('rewardProvider')) {
            if (data.hasOwnProperty('rewardAction') && !data.rewardAction)
                setRewardProvider(null)
            else {
                setRewardProvider({
                    ...rewardProvider,
                    ...data,
                })
            }
        }
    }

    const onUploadPhoto = (propertyName) => {
        setUploadPhotoProperty(propertyName)
    }

    const onUploadCsv = () => {
        setCodesFile(null)
        setCodes(null)
        fileSelector.current.click();
    }

    const onFileSelect = (event) => {
        const [file,] = event.target.files

        if (file.type != 'text/csv'){
            window.alert('Invalid CSV File')
            return
        }
        dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:true});
        event.target.value=null
        setCodesFile(file.name)
        const reader = new FileReader();
        reader.onerror= (e) => { 
            window.alert(e)
            dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false})
        };
        reader.onload = async (e) => {
            dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
            setCodes({csv: reader.result})
            setHasCode(true)
        };
        reader.readAsText(file, 'UTF-8');
    }

    const onConfirmPhoto = (photo) => {
        
        switch(uploadPhotoProperty) {
            case 'asset': {
                setAsset(asset => {
                    return {
                        ...asset,
                        dataURL: photo
                    }
                })
                break
            }
            case 'bannerAsset': {
                setBannerAsset(bannerAsset => {
                    return {
                        ...bannerAsset,
                        dataURL: photo
                    }
                })
                break
            }
            case 'logoAsset': {
                setLogoAsset(logoAsset => {
                    return {
                        ...logoAsset,
                        dataURL: photo
                    }
                })
                break
            }
        }
    }

    const onRemoveAsset= (key) => {
        
        switch(key) {
            case 'asset': {
                setAsset(null)
                break
            }
            case 'bannerAsset': {
                if (!isDisabled(key))
                    setBannerAsset(null)
                break
            }
            case 'logoAsset': {
                setLogoAsset(null)
                break
            }
        }
    }

    const toggleOptionalConfig = () => {
        setCollapseOptionalConfig(!collapseOptionalConfig);
    };

    useEffect(() => {
        if (rewardOptions.length > 0) {
            const firstRewardOption = rewardOptions.find(ro => ro.enabled)

            if (firstRewardOption) {
                setRewardAction(rewardAction => ({
                    ...rewardAction,
                    resources: firstRewardOption.resources
                }))
            }
        }
    }, [rewardOptions])

    useEffect(() => {
        if (search) {
            const getRewards = async () => {
                setSearching(true)
                try {
                    const result = await DatabaseService.getRewardsV2({
                        type: categoryToRewardType[rewardType],
                        query: search,
                    })
                    setInventoryRewards(result.rewards)
                } catch(err) {
                    window.alert(err.message || 'An error occurred - check logs');
                    console.error(err);
                } finally {
                    setSearching(false)
                }
            }
            getRewards()
        } else {
            setInventoryRewards([])
        }
    }, [search])

    useEffect(() => {
        if (searchSegment) {
            const getSegments = async () => {
                setSearching(true)
                try {
                    const result = await DatabaseService.getSegments({query: searchSegment})
                    setSegments(result.rows)
                } catch(err) {
                    window.alert(err.message || 'An error occurred - check logs');
                    console.error(err);
                } finally {
                    setSearching(false)
                }
            }
            getSegments()
        } else if (searchSegment == ''){
            setSegment(null)
            setSegments([])
        } else {
            // if(segment)
            //     setSegment(null)
            setSegments([])
        }
    }, [searchSegment])

    const createFromOptions = (
        <div>
            <h2 style={style.centerAlign}>{t('create_choice_heading')}</h2>
            <div style={style.flex}>
                <Button type='button' 
                    style={style.uploadButton} 
                    onClick={()=>setCreateChoice('existing')}
                    >
                    {t('create_choice_existing')}
                </Button>
                <Button type='button' 
                    style={style.uploadButton}
                    onClick={()=>setCreateChoice('new')}
                    disabled={rewardCategory === 'gift_cards'}
                    >
                    {t('create_choice_new')}
                </Button>
            </div>
        </div>
    )
    
    // const preProcessCreate

    const createFromExisting = (selectedInventoryReward) => {
        const {companyId, hasCode, maxQuantityPerUser, quantity,
            asset, banner, catalog} = selectedInventoryReward
        // prefill
        setIsPublic(companyId == null)
        setBannerAsset(banner)
        setLogoAsset(asset)
        setHasCode(hasCode)
        setMaxQuantityPerUser(maxQuantityPerUser)
        setQuantity(quantity)
        setCatalog(catalog)
        setLocales(locales => {
                // reward locales
                selectedInventoryReward.locales.forEach(translation => {
                    locales[translation.locale].offer = translation.offer || ''
                    locales[translation.locale].description = translation.description || ''

                    // Optional
                    if(translation.template)
                        locales[translation.locale].template = translation.template
                    if(translation.terms)
                        locales[translation.locale].terms = translation.terms
                    if (translation.termsUrl)
                        locales[translation.locale].termsUrl = translation.termsUrl
                    if (translation.brand)
                        locales[translation.locale].brand = translation.brand
                    if (translation.sku)
                        locales[translation.locale].sku = translation.sku

                    // Update from admin panel not supported as of now, set these only if they are present in the existing reward
                    if (translation.offerUrl)
                        locales[translation.locale].offerUrl = translation.offerUrl   
                })

                if (selectedInventoryReward.providerActivity) {
                    selectedInventoryReward.providerActivity.locales.forEach(translation => {
                        locales[translation.locale].providerActivityBaseURL = translation.baseURL || ''
                    })
                }
            return locales
        })

        setShowPage('create')
    }


    const searchInventoryReward = (
        <div>
            <h2 style={style.centerAlign}>{t('search_inventory_reward')}</h2>
            <div style={style.centerAlign}> 
                <Search
                    loading={searching}
                    onResultSelect={(e, {result}) => {
                        setSelectedInventoryReward(result)
                    }}
                    onSearchChange={(e) => {
                        setSearch(e.target.value)
                    }}
                    resultRenderer = {(reward) => <RewardSearchCard reward={reward}/>}
                    showNoResults={!searching}
                    results={inventoryRewards}
                    value={search}
                    fluid={true}
                    placeholder={t('search_inventory_reward')}
                />
            </div>
            { selectedInventoryReward && 
                <div style={style.centerAlign}>
                    <div style={style.rewardCardContainer}>
                        <RewardCard reward={selectedInventoryReward} />
                    </div>
                    <Button type='button' 
                            style={style.uploadButton} 
                            onClick={() => createFromExisting(selectedInventoryReward)}>
                        {t('continue')}
                    </Button>
                </div>
            }
        </div>
    )

    useEffect(() => {
        if(!update) 
            setModalPos({top: '1rem'})
        else
            setModalPos({top: 'auto'})
    }, [update])

    useEffect(()=> {
        if (!createChoice) return
        if (createChoice === 'existing')
            setShowPage('searchReward')
        else
            setShowPage('create')
    }, [createChoice])

    const modalHeader = useMemo(() => {
        let header = update ? t('updateRewardV2_modal_header') : t('addRewardV2_modal_header')
        const warning = isReadOnly ? t('no_edit_permission') : ''
        
        return (
            <div>
                <div>{header}</div>
                <div style={style.warning}>{warning}</div>
            </div>
        )
    }, [update, isReadOnly])

    const contentForm = (
        <form onSubmit={onSubmit}>
            <Modal.Description>
                <br />
                { rewardActionParam?.id && Boolean(localStorage.master  === 'true') && 
                    <div>
                        <span>{t('rewardActionId')}:</span>
                        <span style={{margin: "0 8px"}}>{rewardActionParam.id}</span>
                    </div>
                }
                
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div>{t('Primary Language')}</div>
                        <Dropdown options={languages}
                                value={preferredLanguage}
                                onChange={onChangePreferredLanguage}
                                selection
                                closeOnChange
                                disabled={isReadOnly}
                                />
                    </div>
                    
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div>{t('Category')}</div>
                        <Dropdown options={categories}
                                // disabled={Boolean(!update)}
                                // disabled={true}
                                value={category}
                                onChange={onChangeCategory}
                                selection
                                closeOnChange
                                disabled={isReadOnly}
                                />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div>{t('reward_type')}</div>
                        <Dropdown options={
                            [   {key: '', text: undefined, value: undefined},
                                ...Object.keys(categoryToRewardActionType)
                                    .map(x=>({key:x, value:x, text:x}))
                            ]
                        }
                                // disabled={Boolean(!update)}
                                // disabled={true}
                                value={rewardType}
                                onChange={onChangeRewardType}
                                selection
                                closeOnChange
                                disabled={isReadOnly}
                                />
                    </div>
                </div>
                <br />
                <br />
                <Tab panes={languages.map(language=>({
                    menuItem:language.text,
                    render:() =>
                        <RewardLocalePane t={t} 
                            locale={language.value}
                            data={locales[language.value]}
                            onChange={(event) => onChangeLocales(event, language.value)}
                            valueChange = {valueChange}
                            isDisabled = {isDisabled}
                            isReadOnly = {isReadOnly}
                        />
                    }))}
                />
                
                <br />
                <div style={style.flex}>
                    <div>
                        <span>{t('Public')}</span>
                        <Checkbox name='isPublic' style={{margin: "0 1rem"}} checked={isPublic} onClick={onChangePublic} 
                            disabled={isDisabled('isPublic')}/>
                    </div>
                   
                    <div>
                        <span>{t('Auction')}</span>
                        <Checkbox name='isAuction' style={{margin: "0 1rem"}} checked={isAuction} onClick={onChangeAction} 
                        disabled={isDisabled('isAuction')}/>
                    </div>
                </div>
                   
                
                <div style={style.flex}>
                    <div style={style.column}>
                        {Boolean(logoAsset && (logoAsset.url || logoAsset.dataURL)) &&
                            <div style={style.relative}>
                                <img style={style.imagePreview} src={logoAsset.dataURL || logoAsset.url} alt='' />
                                {/* <div style={style.IconOnImage} onClick={()=>onRemoveAsset('logoAsset')}><Icon name="remove circle" /></div> */}
                            </div>
                        }
                        <h5 style={style.centerAlign}>Logo</h5>
                        <Button type='button' 
                                style={style.uploadButton} 
                                onClick={()=>onUploadPhoto('logoAsset')}
                                disabled={isDisabled('logoAsset')}
                                >
                            {t('Upload Image')}
                        </Button>
                    </div>
                    <div style={style.column}>
                        {Boolean(asset && (asset.url || asset.dataURL)) &&
                            <div style={style.relative}>
                                <img style={style.imagePreview} src={asset.dataURL || asset.url} alt='' />
                                {/* <div style={style.IconOnImage} onClick={()=>onRemoveAsset('asset')}><Icon name="remove circle" /></div> */}
                            </div>
                        }
                        <h5 style={style.centerAlign}>Tile</h5>
                        <Button type='button' 
                                style={style.uploadButton} 
                                onClick={()=>onUploadPhoto('asset')}
                                disabled={isDisabled('asset')}
                                >
                            {t('Upload Image')}
                        </Button>
                    </div>
                    <div style={style.column}>
                        {Boolean(bannerAsset && (bannerAsset.url || bannerAsset.dataURL)) &&
                            <div style={style.relative}>
                                <img style={style.imagePreview} src={bannerAsset.dataURL || bannerAsset.url} alt='' />
                                <div style={style.IconOnImage} onClick={()=>onRemoveAsset('bannerAsset')}>
                                    <Icon name="remove circle" disabled={isDisabled('bannerAsset')} />
                                </div>
                            </div>
                        }
                        <h5 style={style.centerAlign}>Banner</h5>
                        <Button type='button' 
                                style={style.uploadButton} 
                                onClick={()=>onUploadPhoto('bannerAsset')}
                                disabled={isDisabled('bannerAsset')}
                                >
                            {t('Upload Image')}
                        </Button>
                    </div>
                </div>

                <h3 style={style.flex}>
                    {t('reward_options')}
                    <Icon name='add circle' style={style.addButton} onClick={handleAddRewardOption} />
                </h3>
                
                <div style={{overflowX: 'auto'}}>
                    {
                        rewardOptions.map((rewardOption, idx)=> (
                            <div key={idx} style={{...style.flex, alignItems: 'flex-start'}}>
                                <span style={{margin: "1.6rem 0"}}>{idx+1}.</span>
                                { Boolean(localStorage.master  === 'true') && 
                                    <div>
                                        <div>{t('rewardId')}</div>
                                        <div>{rewardOption.reward.id}</div>
                                    </div>
                                }
                                <div>
                                    <div>{t('monetary_value_prompt')}</div>
                                    <Input 
                                        name={`rewardOptions.${idx}.reward.price`}
                                        label={{ basic: true, content:'$'}} 
                                        type='number'
                                        size='mini'
                                        value={rewardOption.reward.price/100} 
                                        onChange={onUpdateReward(rewardOption, 'price')}
                                        disabled={isReadOnly}
                                        />
                                </div>
                                <div>
                                    <div>{t('reward_value_prompt')}</div>
                                    <Input 
                                        name={`rewardOptions.${idx}.resources`}
                                        iconPosition='left'
                                        type='number'
                                        size='mini'
                                        value={rewardOption.resources}
                                        onChange={onUpdateRewardOption(rewardOption, 'resources')}
                                        disabled={isReadOnly}
                                    >
                                        <Icon name="gem" style={style.gemIcon}/>
                                        <input />
                                    </Input>
                                </div>
                                    
                                {resourceScaler &&
                                <div>
                                        <div>{t('reward_scaled_value_prompt')}</div>
                                        <Input
                                            labelPosition='left'
                                            type='number'
                                            value={rewardOption.resources * resourceScaler || ""} 
                                            size='mini'
                                            disabled={isReadOnly}
                                            >
                                    <Label><i style={style.star} className="icon-Optimollar"/> x {resourceScaler}</Label>
                                    <input disabled />
                                    </Input> 
                                </div> }
                               
                                { !categoryDefaults[rewardCategory]?.hiddenFields.includes('rewardOptionQuantity') &&
                                    <div>
                                        <div>{t('Milestone')}</div>
                                        <Input 
                                            name={`rewardOptions.${idx}.quantity`}
                                            label={{ basic: true, content:''}} 
                                            type='number'
                                            size='mini'
                                            value={rewardOption.quantity || undefined} 
                                            onChange={onUpdateRewardOption(rewardOption, 'quantity')}
                                            disabled={isReadOnly}
                                            />
                                    </div>
                                }

                                <div>
                                    <div>{t('quantity_block')}</div>
                                    <Input 
                                        name={`rewardOptions.${idx}.quantityBlock`}
                                        label={{ basic: true, content:''}} 
                                        type='number'
                                        size='mini'
                                        value={rewardOption.quantityBlock} 
                                        onChange={onUpdateRewardOption(rewardOption, 'quantityBlock')}
                                        disabled={isDisabled('rewardOptionQuantityBlock')}
                                        />
                                </div>

                                { !categoryDefaults[rewardCategory]?.hiddenFields.includes('sku') && !rewardOptions[0].reward.variable &&
                                    <div>
                                        <div>{t('sku_prompt')}</div>
                                        <Input 
                                            value={rewardOption.reward.sku.en}
                                            name= {`rewardOptions.${idx}.reward.sku`}
                                            size='mini'
                                            disabled={isDisabled('sku') || rewardOption.reward.variable}
                                            placeholder = {t('sku_prompt')}
                                            onChange={onUpdateReward(rewardOption, 'sku')}
                                            maxLength = "50"
                                        />
                                    </div>
                                    
                                }

                                <div>
                                    <div>{t('visible')}</div>
                                    <Checkbox 
                                        name={`rewardOptions.${idx}.visible`}
                                        size='mini'
                                        disabled={isDisabled('rewardOptionHidden')}
                                        checked={Boolean(rewardOption.visible)} 
                                        onClick={onUpdateRewardOption(rewardOption, 'visible')}
                                        />
                                </div>

                            </div>
                        ))
                    }
                </div>
                
                <br />
                <br />
                <div>
                    <h3>Optional Configuration 
                        <Button
                            style = {{backgroundColor: 'white'}}
                            type= 'button'
                            onClick={toggleOptionalConfig}
                        >
                            <span style={collapseOptionalConfig 
                                ? style.chevronIcon
                                : style.chevronIconDown}
                            ></span>
                        </Button>
                    </h3>
                    {!collapseOptionalConfig && (
                    <div>
                        <div style={style.flex}>
                            <div>
                                <div>{t('Currency')}</div>
                                <span>
                                    <Dropdown options={currencies}
                                        value={currency}
                                        onChange={onChangeCurrency}
                                        selection
                                        closeOnChange 
                                        disabled={isDisabled('currency')}
                                        />
                                </span>
                            </div>
                            <div>
                                <div>{t('Priority')}</div>
                                <span>
                                    <Dropdown options={priorities}
                                        value={priority}
                                        onChange={onChangePriority}
                                        selection
                                        closeOnChange
                                        disabled={isReadOnly}
                                        />
                                </span>
                            </div>
                            <div>
                                <div>{t('quantity_prompt')}</div>
                                <Input name='rewardQuantity'
                                    label={{ basic: true, content:''}} 
                                    type='number' 
                                    value={quantity} 
                                    onChange={onChangeQuantity}
                                    disabled={isDisabled('quantity')}
                                    />
                            </div>

                            { !categoryDefaults[rewardCategory]?.hiddenFields.includes('sku') && rewardOptions[0]?.reward.variable &&
                                <div>
                                    <div>{t('sku_prompt')}</div>
                                    <Input 
                                        value={rewardOptions[0]?.reward.sku.en}
                                        name= {`sku`}
                                        disabled={isDisabled('sku')}
                                        placeholder = {t('sku_prompt')}
                                        onChange={updateAllRewards('sku')}
                                        maxLength = "50"
                                    />
                                </div>
                                
                            }
                        </div>
                        <div>
                            <div>{t('Segment')}</div>
                            <Search
                                loading={searching}
                                onResultSelect={(e, {result}) => {
                                    setSegment(result)
                                    setSearchSegment(null)
                                }}
                                onSearchChange={(e) => {
                                    setSearchSegment(e.target.value)
                                }}
                                resultRenderer = {(segment) => <div>{segment.name}</div>}
                                showNoResults={!searching}
                                results={segments}
                                value={searchSegment || segment?.name}
                                disabled={isReadOnly}
                            />
                        </div>
                        <h4>Availability</h4>
                        <div style={style.flex}>
                            <div>
                                <div>Available at</div>
                                <DateTimePicker
                                    style={style.datePicker}
                                    value={availableAt ? new Date(availableAt) : null}
                                    step={60}
                                    onChange={value=>setAvailabeAt(value)}
                                    onKeyPress={(event)=>{event.preventDefault()}}
                                    min={moment().startOf('hour').toDate()}
                                    disabled={isReadOnly}
                                />
                            </div>
                            <div>
                                <div>Ends at</div>
                                <DateTimePicker
                                    style={style.datePicker}
                                    value={endsAt ? new Date(endsAt) : null}
                                    step={60}
                                    onChange={value=>setEndsAt(value)}
                                    onKeyPress={(event)=>{event.preventDefault()}}
                                    min={moment().startOf('hour').toDate()}
                                    disabled={isReadOnly}
                                />
                            </div>
                        </div>
                        <h4>Scheduling</h4>
                        <div style={style.flex}>
                            <div>
                                <div>Recurrence</div>
                                <span>
                                    <Dropdown options={schedules}
                                        value={schedule?.recurrenceType}
                                        onChange={(e, {value})=>onChangeSchedule({recurrenceType: value})}
                                        selection
                                        closeOnChange 
                                        disabled={isReadOnly}
                                        />
                                </span>
                            </div>
                            <div>
                                <div>Next At</div>
                                <DateTimePicker
                                    style={style.datePicker}
                                    value={schedule?.recurrenceUtcNextAt ? new Date(schedule.recurrenceUtcNextAt): null}
                                    step={60}
                                    onChange={value=>onChangeSchedule({recurrenceUtcNextAt: value})}
                                    onKeyPress={(event)=>{event.preventDefault()}}
                                    min={moment().startOf('hour').toDate()}
                                    disabled={isReadOnly}
                                />
                            </div>
                            <div>
                                <div>Ends At</div>
                                <DateTimePicker
                                    style={style.datePicker}
                                    value={schedule?.recurrenceEndsAt ? new Date(schedule.recurrenceEndsAt): null}
                                    step={60}
                                    onChange={value=>onChangeSchedule({recurrenceEndsAt: value})}
                                    onKeyPress={(event)=>{event.preventDefault()}}
                                    min={moment().add(1, 'hour').startOf('hour').toDate()}
                                    disabled={isReadOnly}
                                />
                            </div>
                        </div>

                        <h4>Reward Provider</h4>
                        <div style={style.flex}>
                            {
                                rewardProvider?.tag &&
                                <div>
                                    <span>{"Tag: "}</span>
                                    <span>{rewardProvider?.tag}</span>
                                </div>
                            }
                           
                            <div>
                                <div>Id</div>
                                <Input name= 'rewardProviderId'
                                    value={rewardProvider?.id}
                                    type='number'
                                    disabled={isDisabled('rewardProvider')}
                                    placeholder={t('reward_provider_id')}
                                    onChange={(e, {value}) => onChangeRewardProvider({id: value})}
                                />
                            </div>
                            <div>
                                <div>Reward Provider Value</div>
                                <Input name= 'rewardProviderValue'
                                    value={rewardProviderValue}
                                    type='number'
                                    disabled={isDisabled('rewardProviderValue')}
                                    placeholder={t('reward_provider_value')}
                                    onChange={(e, {value}) => setRewardProviderValue(value)}
                                />
                            </div>
                        </div>
                        
                        <h4>{'Sponsor (Linkout)'}</h4>
                        <div>
                            <div>Enter Existing Id</div>
                            <Input name= 'providerActivityId'
                                value={providerActivity?.id}
                                type='number'
                                disabled={isDisabled('providerActivity')}
                                placeholder={t('link_existing_sponsor')}
                                onChange={(e, {value}) => onChangeProviderActivity({id: value})}
                            />
                        </div>
                        <h4>OR</h4>
                        <h4>{'Create New Linkout'}</h4>
                        <div style={style.flex}>
                            
                            <div>
                                <div>Reward Action</div>
                                <span>
                                    <Dropdown options={providerActivityRewardActions}
                                        value={providerActivity?.rewardAction}
                                        disabled={isDisabled('providerActivity')}
                                        onChange={(e, {value}) => onChangeProviderActivity({rewardAction: value})}
                                        selection
                                        closeOnChange />
                                </span>
                            </div>
                            <div>
                                <div>Action Type</div>
                                <span>
                                    <Dropdown options={providerActivityActionTypes}
                                        value={providerActivity?.actionType}
                                        disabled={isDisabled('providerActivity')}
                                        onChange={(e, {value}) => onChangeProviderActivity({actionType: value})}
                                        selection
                                        closeOnChange />
                                </span>
                            </div>
                            <Tab panes={languages.map(language=>({
                                menuItem:language.text,
                                render:() =>
                                    <ProviderActivityLocalePane t={t} 
                                        locale={language.value}
                                        data={locales[language.value]}
                                        onChange={(event) => onChangeLocales(event, language.value)}
                                        valueChange = {valueChange}
                                        rewardCategory = {rewardCategory}
                                        isDisabled = {isDisabled}
                                        isReadOnly={isReadOnly}
                                    />
                                }))}
                            />
                        </div>
                        <div style={style.flex}>
                            <div>
                                <div>Sponsor Tag</div>
                                <Input name= 'providerActivityTag'
                                    value={providerActivity?.sponsorTag}
                                    maxLength='50'
                                    disabled={isDisabled('providerActivity')}
                                    onChange={(e, {value}) => onChangeProviderActivity({sponsorTag: value})}
                                />
                            </div>
                            <div>
                                <div>Tag</div>
                                <Input name= 'providerActivityTag'
                                    value={providerActivity?.tag}
                                    maxLength='50'
                                    disabled={isDisabled('providerActivity')}
                                    onChange={(e, {value}) => onChangeProviderActivity({tag: value})}
                                />
                            </div>
                            <div>
                                <div>{t('reward_value_prompt')}</div>
                                <Input name='providerActivityResources' 
                                    iconPosition='left'
                                    type='number'
                                    disabled={isDisabled('providerActivity')}
                                    value={providerActivity?.resources}
                                    onChange={(e, {value}) => onChangeProviderActivity({resources: value})}
                                >
                                    <Icon name="gem" style={style.gemIcon}/>
                                    <input />
                                </Input>
                            </div>  
                        </div>
                        <h4>Misc</h4>
                        <div style={style.flex}>
                            <div style={style.column}>
                                <span>Has Stepper</span>
                                <Checkbox 
                                    name='hasStepper'
                                    disabled={isDisabled('hasStepper')}
                                    style={{margin: "0 1rem"}} 
                                    checked={hasStepper} 
                                    onClick={onChangeHasStepper} />
                            </div>
                            <div style={style.column}>
                                <span>Has Progress Bar</span>
                                <Checkbox 
                                    name='hasProgressBar' 
                                    disabled={isDisabled('hasProgressBar')}
                                    style={{margin: "0 1rem"}} 
                                    checked={hasProgressBar} 
                                    onClick={onChangeHasProgressBar} />
                            </div>
                            <div style={style.column}>
                                <span>Limit to 1/user</span>
                                <Checkbox 
                                    name='maxQuantityPerUser' 
                                    disabled={isDisabled('maxQuantityPerUser')}
                                    style={{margin: "0 1rem"}} 
                                    checked={Boolean(maxQuantityPerUser)} 
                                    onClick={onChangeMaxQuantityPerUser}
                                    />
                            </div>
                            
                        {/* </div> */}
                        {/* <div style={style.flex}> */}
                            <div style={style.column}>
                                <div>
                                    <span>Has Codes</span>
                                    <Checkbox 
                                        name='hasCode' 
                                        style={{margin: "0 1rem"}} 
                                        checked={hasCode} 
                                        onClick={onChangeHasCode} 
                                        disabled={isDisabled('hasCode')}
                                    />
                                </div>
                            </div>
                            
                            <div>
                                <input ref={fileSelector} type="file" style={{display:'none'}} onChange={onFileSelect} disabled={isReadOnly}/>
                                <Button type='button' style={style.button} 
                                    onClick={onUploadCsv}
                                    disabled={isReadOnly}
                                >
                                        {codes ? t('Upload Success') : t('Upload Codes')}
                                </Button>
                                <p style={style.centerAlign}>{codesFile}</p>
                            </div>
                        </div>

                        <Tab panes={languages.map(language=>({
                            menuItem:language.text,
                            render:() =>
                                <OptionalRewardLocalePane t={t} 
                                    locale={language.value}
                                    data={locales[language.value]}
                                    onChange={(event) => onChangeLocales(event, language.value)}
                                    valueChange = {valueChange}
                                    rewardCategory = {rewardCategory}
                                    isDisabled = {isDisabled}
                                    isReadOnly = {isReadOnly}
                                />
                            }))}
                        />
                </div>        
                )}
            </div>
                <DeletePromptContainer ref={deleteModalRef} onDeleteConfirmation = {onDelete} />
                {update &&
                    <Button 
                        style={buttons.delete} 
                        type='button' 
                        onClick={() => {deleteModalRef.current.showDeletePrompt()}}
                        disabled={isReadOnly}
                        >
                        {t('Delete')}
                    </Button>            
                }
                <Button
                    type='submit'
                    style={update ? style.updateButton : style.saveButton}
                    disabled={!isValid() || isReadOnly}>
                    {update ? t('Save') : t('Create')}
                </Button>
                <br />
                {Boolean(uploadPhotoProperty) &&
                    <ChoosePhotoContainer
                        onConfirmPhoto={photo=>onConfirmPhoto(photo)}
                        width={cropperDimensions[uploadPhotoProperty]?.width}
                        height={cropperDimensions[uploadPhotoProperty]?.height}
                        onClose={onUploadPhoto}
                    />
                }
            </Modal.Description>
        </form>
    )

    return !isLoading && (
        <Modal open style={{...style.content, ...modelPos}} onClose={onClose} size='large'>
            <Modal.Header>{modalHeader}</Modal.Header>
            <Modal.Content>
                { Boolean(showPage === 'createSelection') && createFromOptions }
                { Boolean(['edit', 'create'].includes(showPage)) && contentForm }
                { Boolean(showPage === 'searchReward') && searchInventoryReward }
            </Modal.Content>
        </Modal>
    )
}

export default connect()(withTranslation('rewards')(EditRewardV2ModalContainer));
